import React, { createContext, useContext, useState } from 'react';
import { useScroll } from '@react-spring/web';

const ScrollContext = createContext({
  scrollProgress: 0,
  scrollToProgress: () => {},  // Add a placeholder function for scrolling to a specific progress
  snapToProgress: () => {}  // Add a placeholder function for scrolling to a specific progress
});

export const clampProgress = (scrollProgress, startScale = 0, endScale = 1) => {
  const scaledProgress = (scrollProgress - startScale) / (endScale - startScale);
  return Math.min(Math.max(scaledProgress, 0), 1);
};

export const useScrollProgress = (startScale = 0, endScale = 1) => {
  const { scrollProgress } = useContext(ScrollContext);
  // console.log(scrollProgress);
  const scaledProgress = (scrollProgress - startScale) / (endScale - startScale);
  const clampedProgress = Math.min(Math.max(scaledProgress, 0), 1); // Clamp the value between 0 and 1

  return clampedProgress;
};

export const ScrollProvider = ({ children, containerRef }) => {
  const [scrollProgress, setScrollProgress] = useState(0);

  useScroll({
    // container: containerRef,
    onChange: (x) => {
      // console.log(x.value.scrollYProgress);
      setScrollProgress(x.value.scrollYProgress);
    },
  });

  // const scrollToProgress = (progress) => {
  //   const containerHeight = containerRef.current?.scrollHeight || 0;
  //   const windowHeight = window.innerHeight;
  //   const scrollY = progress * (containerHeight - windowHeight);
  //   if (containerRef.current) {
  //     containerRef.current.scrollTo({
  //       top: scrollY,
  //       behavior: "smooth"
  //     });
  //   }
  // };

  const scrollToProgress = (progress) => {
    const documentHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;
    const scrollY = progress * (documentHeight - windowHeight);
    // console.log(progress);
    // console.log(documentHeight);
    // console.log(windowHeight);
    // console.log("TO: ", scrollY);
    setTimeout(() => window.scrollTo({
      top: scrollY,
      behavior: "smooth"
    }), 100);
  };

  const snapToProgress = (progress) => {
    const documentHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;
    const scrollY = progress * (documentHeight - windowHeight);
    // console.log(progress);
    // console.log(documentHeight);
    // console.log(windowHeight);
    // console.log("TO: ", scrollY);
    setTimeout(() => window.scrollTo({
      top: scrollY
    }), 100);
  };
  

  return (
    <ScrollContext.Provider value={{ scrollProgress, scrollToProgress, snapToProgress }}>
      {children}
    </ScrollContext.Provider>
  );
};

// Export utility methods for scrolling to specific progress values
export const useScrollToTop = () => {
  // console.log("useScrollToTop");
  const { scrollToProgress } = useContext(ScrollContext);
  return () => scrollToProgress(0);
};

export const useScrollToPurpose = () => {
  // console.log("useScrollToPurpose");
  const { scrollToProgress } = useContext(ScrollContext);
  return () => scrollToProgress(0.1295);
};

export const useScrollToIssue = () => {
  // console.log("useScrollToIssue");
  const { scrollToProgress } = useContext(ScrollContext);
  return () => scrollToProgress(0.3032);
};

export const useScrollToAssess = () => {
  // console.log("useScrollToAssess");
  const { scrollToProgress } = useContext(ScrollContext);
  return () => scrollToProgress(0.6471);
};

export const useScrollToAfterAssess = () => {
  // console.log("useScrollToAfterAssess");
  const { snapToProgress } = useContext(ScrollContext);
  return () => snapToProgress(0.7016);
};

// export const useScrollToActNow = () => {
//   // console.log("useScrollToActNow");
//   const { scrollToProgress } = useContext(ScrollContext);
//   return () => scrollToProgress(0.968);
// };

// export const useScrollToAssess = () => {
//   return () => {
//     const element = document.getElementById('assessPhone');
//     if (element) {
//       element.scrollIntoView({ behavior: "smooth" });
//     }
//   };
// };

// export const useScrollToAfterAssess = () => {
//   return () => {
//     const element = document.getElementById('afterAssess');
//     if (element) {
//       element.scrollIntoView({ behavior: "smooth" });
//     }
//   };
// };

export const useScrollToActNow = () => {
  return () => {
    const element = document.getElementById('actNow');
    // if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    // }
  };
};
