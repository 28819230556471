import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Button = ({
  label,
  onClick,
  icon,
  isIconOnly,
  textColor,
  bgColor,
  hoverTextColor,
  hoverBgColor,
  activeTextColor,
  activeBgColor,
  zIndex,
  extraClasses,
  isSmall
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const baseStyle = `relative border-none rounded-full font-schabo tracking-wide text-base/[16px] pointer-events-auto`;
  const iconButtonStyle = `${baseStyle} flex items-center justify-center`;
  const textButtonStyle = `${baseStyle} pt-1 apple:pt-1`;

  let height = 'h-12';

  if (isIconOnly && isSmall) {
    height = 'w-9 h-9';
  } else if (isIconOnly && !isSmall) {
    height = 'w-12 h-12';
  } else if (isSmall) {
    height = 'h-9';
  }

  const currentTextColor = isActive ? activeTextColor : (isHovered ? hoverTextColor : textColor);
  const currentBgColor = isActive ? activeBgColor : (isHovered ? hoverBgColor : bgColor);

  return (
    <button
      className={`${isIconOnly ? iconButtonStyle : textButtonStyle} ${currentTextColor} ${currentBgColor} ${zIndex} ${extraClasses} ${height}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={() => setIsActive(true)}
      onMouseUp={() => setIsActive(false)}
      onClick={onClick}
    >
      {isIconOnly ? icon : label}
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element,
  isIconOnly: PropTypes.bool,
  textColor: PropTypes.string,
  bgColor: PropTypes.string,
  hoverTextColor: PropTypes.string,
  hoverBgColor: PropTypes.string,
  activeTextColor: PropTypes.string,
  activeBgColor: PropTypes.string,
  zIndex: PropTypes.string,
  extraClasses: PropTypes.string,
  isSmall: PropTypes.bool
};

Button.defaultProps = {
  label: null,
  icon: null,
  isIconOnly: false,
  textColor: 'text-white',
  bgColor: 'bg-black',
  hoverTextColor: 'text-white',
  hoverBgColor: 'bg-gray-700',
  activeTextColor: 'text-white',
  activeBgColor: 'bg-gray-900',
  zIndex: '',
  extraClasses: '',
  isSmall: false
};

export default Button;
