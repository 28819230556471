import React, { createContext, useState, useContext } from 'react';

import africaSvg from '../assets/images/regions/africa.svg';
import asiaPacificSvg from '../assets/images/regions/asiapacific.svg';
import northAsiaSvg from '../assets/images/regions/northasia.svg';
import europeSvg from '../assets/images/regions/europe.svg';
import latinAmericaSvg from '../assets/images/regions/latinamerica.svg';
import northAmericaSvg from '../assets/images/regions/northamerica.svg';
import oceaniaSvg from '../assets/images/regions/oceania.svg';

const RegionContext = createContext();

export const useRegion = () => useContext(RegionContext);

export const RegionProvider = ({ children }) => {
  const [regions] = useState([
    {
      "name": "Australia & New Zealand",
      "imgUrl": oceaniaSvg,
      "activeUsers": "39 million",
      "co2Kg": "121,205",
      "co2Air": "2,197",
      "fromCapital": "Sydney",
      "toDestination": "Melbourne",
      "co2Cars": "26,621"
    },
    {
      "name": "Latin America",
      "imgUrl": latinAmericaSvg,
      "activeUsers": "439 million",
      "co2Kg": "1,355,642",
      "co2Air": "3,321",
      "fromCapital": "Buenos Aires",
      "toDestination": "New York City",
      "co2Cars": "297,747"
    },
    {
      "name": "North America",
      "imgUrl": northAmericaSvg,
      "activeUsers": "352 million",
      "co2Kg": "1,086,985",
      "co2Air": "4,598",
      "fromCapital": "New York City",
      "toDestination": "London",
      "co2Cars": "238,740"
    },
    {
      "name": "Europe",
      "imgUrl": europeSvg,
      "activeUsers": "496 million",
      "co2Kg": "1,531,660",
      "co2Air": "6,381",
      "fromCapital": "Paris",
      "toDestination": "NYC",
      "co2Cars": "336,407"
    },
    {
      "name": "Asia Pacific",
      "imgUrl": asiaPacificSvg,
      "activeUsers": "1.73 billion",
      "co2Kg": "5,342,280",
      "co2Air": "4,880",
      "fromCapital": "Singapore",
      "toDestination": "New York City",
      "co2Cars": "1,173,353"
    },
    {
      "name": "North Asia",
      "imgUrl": northAsiaSvg,
      "activeUsers": "2.01 billion",
      "co2Kg": "6,210,694",
      "co2Air": "8,005",
      "fromCapital": "Tokyo",
      "toDestination": "New York City",
      "co2Cars": "1,364,304"
    },
    {
      "name": "Middle East & Africa",
      "imgUrl": africaSvg,
      "activeUsers": "425 million",
      "co2Kg": "1,312,410",
      "co2Air": "2,187",
      "fromCapital": "Dubai",
      "toDestination": "New York City",
      "co2Cars": "288,251"
    }
  ]);
  const [selectedRegionIndex, setSelectedRegionIndex] = useState(0);

  const selectedRegion = regions[selectedRegionIndex];

  const handlePrevClick = () => {
    setSelectedRegionIndex(prevIndex => (prevIndex === 0 ? regions.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setSelectedRegionIndex(prevIndex => (prevIndex === regions.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <RegionContext.Provider value={{ selectedRegion, handlePrevClick, handleNextClick }}>
      {children}
    </RegionContext.Provider>
  );
};
