import React, {useEffect, useContext, useState, useRef} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { animated, useSpring } from '@react-spring/web';
import { useScrollProgress, clampProgress } from '../../context/ScrollContext';

import caretRight from '../../assets/images/icons/caretRight.svg';

import Button from '../Button';

import svgClose from '../../assets/images/icons/svgClose.svg';
import { SPRING_CONFIG } from '../../hooks/springConfig';
import './Modal.css';

const apiUrl = 'https://d335xe60v21ozw.cloudfront.net/api/v1/send-email';


const _SPRING_CONFIG = {
    ...SPRING_CONFIG
};

const ActNowComponent = ({ startOffset = 0, endOffset = 1 }) => {

  const scrollProgress = useScrollProgress(startOffset, endOffset);

  const remappedStep1Progress = clampProgress(scrollProgress, 0, 0.1);
  // const remappedStep2Progress = clampProgress(scrollProgress, 0.3, 0.4);
  // const remappedStep3Progress = clampProgress(scrollProgress, 0.4, 0.5);
  // const remappedStep4Progress = clampProgress(scrollProgress, 0.5, 0.6);
  //   const remappedStep5Progress = clampProgress(scrollProgress, 0.55, 0.65);
  //   const remappedStep6Progress = clampProgress(scrollProgress, 0.7, 0.8);

    // const [{ slide1Y }, setSlide1Y] = useSpring(() => ({ slide1Y: 0, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    // useEffect(() => {
    //   setSlide1Y({ slide1Y: (1 - remappedStep1Progress) * 0 +25 });
    // }, [remappedStep1Progress, setSlide1Y]);

    // const [{ slide2Y }, setSlide2Y] = useSpring(() => ({ slide2Y: 100, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    // useEffect(() => {
    //   setSlide2Y({ slide2Y: (1 - remappedStep2Progress) * 100 -3.75 });
    // }, [remappedStep2Progress, setSlide2Y]);

    // const [{ slide3Y }, setSlide3Y] = useSpring(() => ({ slide3Y: 100, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    // useEffect(() => {
    //   setSlide3Y({ slide3Y: (1 - remappedStep3Progress) * 100 +25 });
    // }, [remappedStep3Progress, setSlide3Y]);

    const validationSchema1 = Yup.object({
      name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      telNo: Yup.string(),
      interestedIn: Yup.string().required('Required'),
      message: Yup.string().required('Required'),
    });
    
    const validationSchema2 = Yup.object({
      name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      telNo: Yup.string(),
      message: Yup.string().required('Required'),
    });
  
    const [isModalOpen, setIsModalOpen] = useState(false);
    const animation = useSpring({
      transform: isModalOpen ? 'translateY(0)' : 'translateY(100%)',
      config: { tension: 210, friction: 20 },
    });

    const [isModal2Open, setIsModal2Open] = useState(false);
    const animation2 = useSpring({
      transform: isModal2Open ? 'translateY(0)' : 'translateY(100%)',
      config: { tension: 210, friction: 20 },
    });

    useEffect(() => {
      if (isModalOpen || isModal2Open) {
        setSentSuccess(false);
        document.body.classList.add('no-scroll');
        console.log('add noscroll');
      } else {
        document.body.classList.remove('no-scroll');
        console.log('remove noscroll');
      }
  
      return () => {
        document.body.classList.remove('no-scroll');
      };
    }, [isModalOpen, isModal2Open]);

    const [sentSuccess, setSentSuccess] = useState(false);


  return (
    <section  className='relative h-[150vh] w-full bg-wpayellow'>

    {isModalOpen && (
      <animated.div style={animation} className="modal">
        <button className="close-btn" onClick={() => setIsModalOpen(false)}>
          <img src={svgClose} alt="Close" />
        </button>
        <div className="modal-content">
          <div className='font-schabo text-3xl 2xl:text-6xl tracking-wide text-black mb-5'>
            CONNECT WITH KINGFISHER
          </div>
          {sentSuccess? (
            <div className='font-schabo text-xl tracking-wide text-black mb-5 bg-green-300'>
              Thank you for your message, a member of the Kingfisher team will be in contact with you soon.
            </div>
          ) : (<Formik
            initialValues={{
              name: '',
              email: '',
              telNo: '',
              interestedIn: '',
              message: "I’m interested to connect with Kingfisher and would like to chat further"
            }}
            validationSchema={validationSchema1}
            onSubmit={(values, { setSubmitting }) => {
              console.log(values);
              // Use the Fetch API to send the form data
              fetch(apiUrl, {
                method: 'POST', // or 'PUT'
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(values), // Convert form values to JSON
              })
              .then(response => response.json()) // Parse the JSON response
              .then(data => {
                console.log('Success:', data);
                // Perform actions after successful API call
                //set to submitted
                setSentSuccess(true);
              })
              .catch((error) => {
                console.error('Error:', error);
                // Handle errors, such as by displaying a message to the user
                // display error mesage 
              })
              .finally(() => {
                setSubmitting(false); // Reset submission state
              });
            }}
          >
            {({ isSubmitting, errors, touched, handleSubmit }) => (
              <Form className='pb-4'>
                <div className="mb-4">
                  <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">
                    Name
                  </label>
                  <Field
                    name="name"
                    type="text"
                    className={`shadow appearance-none ${
                      errors.name && touched.name ? 'border-b-2 border-red-500' : 'border-b border-gray-300'
  } w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-b-2 focus:border-blue-500`}
                  />
                  <ErrorMessage name="name" component="div" className="text-red-500 text-xs italic" />
                </div>

                <div className="mb-4">
                  <label htmlFor="email" className="block text-gray-700 text-sm font-bold mr-2">
                    Email
                  </label>
                  <Field
                    name="email"
                    type="email"
                    className={`shadow appearance-none ${
                      errors.email && touched.email ? 'border-b-2 border-red-500' : 'border-b border-gray-300'
  } w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-b-2 focus:border-blue-500`}
                  />
                  <ErrorMessage name="email" component="div" className="text-red-500 text-xs italic ml-2" />
                </div>

                <div className="mb-4">
                  <label htmlFor="telNo" className="block text-gray-700 text-sm font-bold mb-2">
                    Tel No.
                  </label>
                  <Field
                    name="telNo"
                    type="text"
                    className={`shadow appearance-none ${
                      errors.telNo && touched.telNo ? 'border-b-2 border-red-500' : 'border-b border-gray-300'
  } w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-b-2 focus:border-blue-500`}
                  />
                  <ErrorMessage name="telNo" component="div" className="text-red-500 text-xs italic mt-1" />
                </div>

                <div className="mb-4">
                  <label htmlFor="interestedIn" className="block text-gray-700 text-sm font-bold mb-2">
                    I'm interested in
                  </label>
                  <div className="relative">
                    <Field
                      name="interestedIn"
                      as="select"
                      className={`shadow appearance-none border-b ${
                        errors.interestedIn && touched.interestedIn ? 'border-red-500' : 'border-gray-300'
                      } w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-b-2 focus:border-blue-500 bg-transparent`}
                    >
                      <option value="">Select an option</option>
                      <option value="Circular Solutions">Circular Solutions</option>
                      <option value="Trade-in Service">Trade-in Service</option>
                      <option value="Start My Own Amnesty">Start My Own Amnesty</option>
                      <option value="CPO device purchase">CPO Device Purchase</option>
                      <option value="Partnership">Partnership</option>
                      <option value="Other">Other</option>
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"/></svg>
                    </div>
                  </div>
                  <ErrorMessage name="interestedIn" component="div" className="text-red-500 text-xs italic mt-1" />
                </div>


                <div className="mb-6">
                  <label htmlFor="message" className="block text-gray-700 text-sm font-bold mb-2">
                    Message
                  </label>
                  <Field
                    name="message"
                    as="textarea"
                    className={`shadow appearance-none ${
                      errors.message && touched.message ? 'border-b-2 border-red-500' : 'border-b border-gray-300'
  } w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-b-2 focus:border-blue-500 h-32`}
                  />
                  <ErrorMessage name="message" component="div" className="text-red-500 text-xs italic mt-1" />
                </div>

                {/* <button type="submit" disabled={isSubmitting}>
                  Submit
                </button> */}
                <Button 
                    textColor={'text-black'} 
                    bgColor={'bg-wpayellow'} 
                    extraClasses={'w-full text-xl'}
                    label='SUBMIT'
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                /> 
              </Form>
            )}
          </Formik>)}
        </div>
      </animated.div>
    )}


    {isModal2Open && (
      <animated.div style={animation2} className="modal">
        <button className="close-btn" onClick={() => setIsModal2Open(false)}>
          <img src={svgClose} alt="Close" />
        </button>
        <div className="modal-content">
          <div className='font-schabo text-3xl 2xl:text-6xl tracking-wide text-black mb-5'>
            START YOUR OWN PHONE AMNESTY
          </div>
          {sentSuccess? (
            <div className='font-schabo text-xl tracking-wide text-black mb-5 bg-green-300'>
              Thank you for your message, a member of the Kingfisher team will be in contact with you soon.
            </div>
          ) : (<Formik
            initialValues={{ 
              name: '', 
              email: '', 
              telNo: '', 
              interestedIn: 'Starting my own Amnesty',
              message: "I would like to start my own amnesty. Let’s chat." // default message
            }}
            validationSchema={validationSchema2}
            onSubmit={(values, { setSubmitting }) => {
              console.log(values);
              // Use the Fetch API to send the form data
              fetch(apiUrl, {
                method: 'POST', // or 'PUT'
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(values), // Convert form values to JSON
              })
              .then(response => response.json()) // Parse the JSON response
              .then(data => {
                console.log('Success:', data);
                // Perform actions after successful API call
                //set to submitted
                setSentSuccess(true);
              })
              .catch((error) => {
                console.error('Error:', error);
                // Handle errors, such as by displaying a message to the user
                // display error mesage 
              })
              .finally(() => {
                setSubmitting(false); // Reset submission state
              });
            }}
          >
            {({ isSubmitting, errors, touched, handleSubmit }) => (
              <Form className='pb-4'>
                <div className="mb-4">
                  <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">
                    Name
                  </label>
                  <Field
                    name="name"
                    type="text"
                    className={`shadow appearance-none ${
                      errors.name && touched.name ? 'border-b-2 border-red-500' : 'border-b border-gray-300'
  } w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-b-2 focus:border-blue-500`}
                  />
                  <ErrorMessage name="name" component="div" className="text-red-500 text-xs italic" />
                </div>

                <div className="mb-4">
                  <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
                    Email
                  </label>
                  <Field
                    name="email"
                    type="email"
                    className={`shadow appearance-none ${
                      errors.email && touched.email ? 'border-b-2 border-red-500' : 'border-b border-gray-300'
  } w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-b-2 focus:border-blue-500`}
                  />
                  <ErrorMessage name="email" component="div" className="text-red-500 text-xs italic" />
                </div>

                <div className="mb-4">
                  <label htmlFor="telNo" className="block text-gray-700 text-sm font-bold mb-2">
                    Tel No.
                  </label>
                  <Field
                    name="telNo"
                    type="text"
                    className={`shadow appearance-none ${
                      errors.telNo && touched.telNo ? 'border-b-2 border-red-500' : 'border-b border-gray-300'
  } w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-b-2 focus:border-blue-500`}
                  />
                  <ErrorMessage name="telNo" component="div" className="text-red-500 text-xs italic mt-1" />
                </div>

                <div className="mb-6">
                  <label htmlFor="message" className="block text-gray-700 text-sm font-bold mb-2">
                    Message
                  </label>
                  <Field
                    name="message"
                    as="textarea"
                    className={`shadow appearance-none ${
                      errors.message && touched.message ? 'border-b-2 border-red-500' : 'border-b border-gray-300'
  } w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-b-2 focus:border-blue-500 h-32`}
                  />
                  <ErrorMessage name="message" component="div" className="text-red-500 text-xs italic mt-1" />
                </div>

                {/* <button type="submit" disabled={isSubmitting}>
                  Submit
                </button> */}
                <Button 
                    textColor={'text-black'} 
                    bgColor={'bg-wpayellow'} 
                    extraClasses={'w-full text-xl'}
                    label='SUBMIT'
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                /> 
              </Form>
            )}
          </Formik>)}
        </div>
      </animated.div>
    )}



      <div className='sticky mx-auto top-0 w-full flex flex-col'>
        <div id='actNow' className='z-50 w-full h-screen act-now_links relative pt-[90px]'>
          <div className='container mx-auto px-5 w-full h-full max-w-lg flex flex-col justify-evenly'>
            <div className='text-center'>
              <div className='font-schabo text-5xl/[3.25rem] tracking-wide text-black pb-4'>
                  ACT NOW
              </div>
              <p className='font-montserrat font-light w-full'>Are you a carrier looking for scalable circular solutions, interested in optimising trade-in, or want to purchase CPO devices?</p>
            </div>
            <div className='flex flex-col justify-evenly max-h-[600px] flex-grow pb-[20px]'>
              <a className='button-act-now border-2 border-black rounded-xl pl-6 pr-4 py-5 md:pr-6 flex justify-between items-center overflow-hidden' href='#0' onClick={() => setIsModal2Open(true)}>
                <div className='flex flex-row gap-5 xl:gap-8 items-center relative z-1'>
                  <div  className='font-schabo text-xl  md: tracking-wide md:text-2xl lg:text-3xl xl:text-4xl'>START YOUR OWN <br/>PHONE AMNESTY</div>
                </div>
                <div className='w-10 h-10 lg:h-12 lg:w-12 xl:h-14 xl:w-14 button-act-now_circle-button-wrap relative'>
                  <div className='button-act-now_circle-button w-full h-full p-3 lg:p-4 bg-black text-white rounded-full flex justify-center items-center lg:bg-transparent lg:border-2 lg:border-black lg:text-black'>
                    <img src={caretRight} alt="Caret Right"  />
                  </div>
                </div>
              </a>
              <a className='button-act-now border-2 border-black rounded-xl pl-6 pr-4 py-5 md:pr-6 flex justify-between items-center overflow-hidden' href='https://kingfisher-mx.com/reinventing-the-mobile-ownership-experience/' target='_blank' >
                <div className='flex flex-row gap-5 xl:gap-8 items-center relative z-1'>
                  <div className='font-schabo text-xl  md: tracking-wide md:text-2xl lg:text-3xl xl:text-4xl'>VISIT THE <br />KINGFISHER WEBSITE<br/> <span className='text-base font-b font-montserrat'>for more information</span> </div>
                </div>
                <div className='w-10 h-10 lg:h-12 lg:w-12 xl:h-14 xl:w-14 button-act-now_circle-button-wrap relative'>
                  <div className='button-act-now_circle-button w-full h-full p-3 lg:p-4 bg-black text-white rounded-full flex justify-center items-center lg:bg-transparent lg:border-2 lg:border-black lg:text-black'>
                  <img src={caretRight} alt="Caret Right"  />
                  </div>
                </div>
              </a>
              <a className='button-act-now border-2 border-black rounded-xl pl-6 pr-4 py-5 md:pr-6 flex justify-between items-center overflow-hidden' href='#0' onClick={() => setIsModalOpen(true)}>
                <div className='flex flex-row gap-5 xl:gap-8 items-center relative z-1'>
                  <div className='font-schabo text-xl  md:  tracking-wide md:text-2xl lg:text-3xl xl:text-4xl'>CONNECT WITH <br />KINGFISHER<br /> <span className='text-base font-montserrat'>to find out more</span></div>
                </div>
                <div className='w-10 h-10 lg:h-12 lg:w-12 xl:h-14 xl:w-14 button-act-now_circle-button-wrap relative'>
                  <div className='button-act-now_circle-button w-full h-full p-3 lg:p-4 bg-black text-white rounded-full flex justify-center items-center lg:bg-transparent lg:border-2 lg:border-black lg:text-black'>
                    <img src={caretRight} alt="Caret Right"  />
                  </div>
                </div>
              </a>
            </div>
            
          </div>
          
        </div>
        
      </div>
    </section>
  );
};

export default ActNowComponent;