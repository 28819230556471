import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import StickyHeader from '../components/StickyHeader';
import FooterComponent from '../components/FooterComponent';
import image01Md from '../assets/images/WPA.png';
// import image01Lg from '../assets/images/WPA.png';
import image02Md from '../assets/images/whoKingfisher.png';
import image02Lg from '../assets/images/whoKingfisher_v2.png';

import CCS from '../assets/images/CCS_logo.svg';
import GSMA from '../assets/images/GSMA_logo.png'


function AboutKingfisher() {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


    return (
      <div>
          <div className="fixed top-0 z-50 h-15 bg-white shadow-md w-full">
            <StickyHeader />
          </div>
        <section className=''>
          <div className='lg:flex lg:items-start lg:min-h-screen'>
            <div className='w-full relative lg:max-h-full lg:w-1/2 bg-slate-200 lg:sticky lg:top-[60px] lg:self-start lg:h-screen'>
              <div className='relative w-full h-full pt-[60%] lg:pt-0'>
                <picture>
                 
                  <img src={image01Md} alt="About Kingfisher - World Phone Amnesty (WPA), collection of phones" className='absolute top-0 w-full h-full object-cover'/>
                </picture>
              </div>
            </div>
            <div className='w-full lg:w-1/2 max-w-xl mx-auto pt-12 pb-16 sm:pt-20 md:pt-24 px-5 lg:px-24 lg:pt-48 xl:pt-64 lg:pb-32 xl:pb-40 xl:px-32 lg:max-w-3xl 2xl:max-w-4xl 2xl:px-28'>
              <h1 className="font-schabo uppercase tracking-wide mb-6 lg:mb-10 text-3xl sm:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl">What is World Phone Amnesty?</h1>
              <div className='text-base leading-relaxed font-montserrat text-wpagrey-500 xl:text-lg'>
                {/* <h2 className='font-bold mb-6 lg:mb-10 text-black xl:text-xl 2xl:text-2xl '>World Phone Amnesty is year of action designed to generate global awareness and drive device circulation, by highlighting, there is a better way to own your phone through a simple behaviour change. When you buy a new phone, hand in your old one. (To give it a new life). </h2> */}
                {/* <p className='mb-4'>World Phone Amnesty (WPA) is a global initiative that aims to drive awareness of a more responsible and sustainable way to own and use our mobile phones.</p>
                <p className='mb-4'>It’s a collaboration with leading telcos and industry bodies to ignite a broader conversation around our mobile phone ownership habits – proving that there is a better way to own your phone.</p>
                <p className='mb-4'>What action do we want people to take? It’s simple, from today, when you buy a new phone, hand in your old one to give it a new life.</p>
                <p className='mb-4'>If everyone in the world extended the life of their phone by just one year, it would save up to 21.4 million tonnes of CO2 emissions annually by 2030. That’s the equivalent of taking 4.7 million cars off the road.</p> */}

                <p className='mb-4'>For too long, our phones have been prematurely discarded the moment a new model is released. It’s this behavior that leads to over 5.3 billion working devices ending up in drawers or landfill – every single year. That’s a lot of unutilized resources, and a lot of potential wasted.</p>
                <p className='mb-4'>World Phone Amnesty (WPA) is a global initiative that aims to drive awareness of a more responsible and sustainable way to own and use our mobile phones.</p>

                <p className='mb-4'>It’s a collaboration with leading telcos and industry bodies to ignite a broader conversation around our mobile phone ownership habits – proving that there is a better way to own your phone.</p>
                <p className='mb-4 font-bold'>What action do we want people to take? It’s simple, from today, when you buy a new phone, hand in your old one to give it a new life.</p>

                <p className='mb-4'>If everyone in the world extended the life of their phone by just one year, it would save up to 21.4 million tonnes of CO<sub>2</sub> emissions annually by 2030. That’s the equivalent of taking 4.7 million cars off the road.</p>
              
              </div>
            </div>
          </div>
        </section>
        <section className=''>
          <div className='lg:flex lg:items-start lg:min-h-screen'>
            <div className='w-full relative lg:max-h-full lg:w-1/2 bg-slate-200 lg:sticky lg:top-[60px] lg:self-start lg:h-screen'>
              <div className='relative w-full h-full pt-[60%] lg:pt-0'>
                <picture>
                  <source srcSet={image02Lg} media="(min-width: 1024px)"/>
                  <img src={image02Md} alt="About Kingfisher - World Phone Amnesty (WPA), collection of phones" className='absolute top-0 w-full h-full object-cover lg:object-right'/>
                </picture>
              </div>
            </div>
            <div className='w-full lg:w-1/2 max-w-xl mx-auto pt-12 pb-16 sm:pt-20 md:pt-24 px-5 lg:px-24 lg:pt-48 lg:pb-32 xl:pb-40 xl:px-32 lg:order-first lg:max-w-3xl 2xl:max-w-4xl 2xl:px-28'>
              <h3 className="font-schabo uppercase tracking-wide mb-6 lg:mb-10 text-3xl sm:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl">Why is Kingfisher leading the Amnesty?</h3>
              <div className='text-base leading-relaxed font-montserrat text-wpagrey-500 xl:text-lg'>
                {/* <h4 className='font-bold mb-6 lg:mb-10 text-black xl:text-xl 2xl:text-2xl'>Kingfisher believes that carriers and consumers deserve a better mobile experience. One that’s simple, accessible, friction-free and delivers superior, sustainable impact and value. </h4> */}
                {/* <p className='mb-4'>That’s why we’re reinventing the phone ownership experience, by transforming the way people buy, own and trade-in mobile devices.</p>
                <p className='mb-4'>People-first by design, we’re dedicated to optimizing the lifecycle of connected devices. Our sustainable and innovative device ownership solutions are better for carriers, their customers, and the planet.</p>
                <p className='mb-4'>Today, phones are designed to last longer than ever, with handsets being built to last for 8+ years (despite an annual upgrade cycle). This longer lifespan means phones have the potential to have 3 or more owners.</p>
                <p className='mb-4'>We know people want to buy the latest phones and that’s OK. That’s why we’ve pioneered the first circular model for mobile phones that extends the lifespan of connected devices through a 1-for-1 exchange. It means consumers can still access the latest devices but safe in the knowledge their old phone will be given a new home – and used to its full potential.</p>
                <p className='mb-4'>Keeping a mobile device in circulation for longer is the fastest way to minimize the carbon impact of new phones. More so than recycling and e-waste recovery, which is the final step once a phone is no longer usable.</p>
                <p className='mb-4'>Through collaborating with leading carriers and sustainable ecosystem partners globally, we’re driving awareness and facilitating active change through our alternative models of phone ownership.</p>
                <p className='mb-4'>These models have the potential to significantly reduce carbon impacts, make high-quality devices more accessible, and further unite the mobile industry towards a more sustainable future.</p>
                <p className='mb-4 font-bold'>Find out more about how our device ownership experience is changing the game for carriers, consumers and the planet at <a href='https://kingfisher-mx.com/' target='_blank' rel="noreferrer" className='underline underline-offset-4 text-wparoyalblue hover:decoration-wpayellow transition-colors duration-200'>kingfisher-mx.com</a></p> */}
                <p className='mb-4'>Kingfisher, a next-gen mobile experience company, completely reinventing the phone ownership experience by transforming the way people buy, own and trade-in mobile devices.</p>
                <p className='mb-4'>People-first by design, we’re dedicated to optimizing the lifecycle of connected devices and our mission is to ensure 100% of mobile phones are returned to circulation.</p>

                <p className='mb-4'>We believe that carriers and consumers deserve a better mobile experience. One that’s simple, accessible, friction-free and delivers superior, sustainable impact and value. With no compromise at all.</p>
                <p className='mb-4'>Today, phones are designed to last longer than ever, with handsets being built to last for 7 - 8+ years (despite an annual upgrade cycle). This longer lifespan means phones have the potential to have 1, 2 or even 3 new owners, with no loss in device experience. However, evidence shows devices are prematurely discarded before the end of their usable life.</p>
                
                <p className='mb-4'>We know people want to buy the latest phones and that’s OK.</p>

                <p className='mb-4'>Through collaborating with leading carriers and sustainable ecosystem partners globally, we’re powering the efficient circulation of new and second-life devices within the global value chain whilst driving awareness and facilitating active change through our alternative models of phone ownership.
                These models have the potential to significantly reduce carbon impacts, make high-quality devices more accessible, and further unite the mobile industry towards a more sustainable future.</p>

                <p className='mb-4 font-bold'>Find out more about how our device ownership experience is transforming the mobile experience economy for carriers, consumers and the planet at <a href='https://kingfisher-mx.com/' target='_blank' rel="noreferrer" className='underline underline-offset-4 text-wparoyalblue hover:decoration-wpayellow transition-colors duration-200'>kingfisher-mx.com</a></p>
                              
              </div>
            </div>
          </div>
          <div className='flex flex-col justify-around my-10 items-center'>
            <p className='mb-4 font-bold'>Industry insights supported by:</p>
            <div className='flex flex-row justify-around mb-10 items-center'>
            <img src={CCS} alt="CCS logo" className='max-w-[20%] h-auto' onClick={() => window.open("https://www.gsma.com/aboutus/", '_blank')}/> 
            <img src={GSMA} alt="GSMA Logo"  className='max-w-[30%] h-auto' onClick={() => window.open("https://www.ccsinsight.com/", '_blank')}/> 
            </div>
            <div className='flex flex-col justify-around mb-10 items-center w-80 text-center'>
              <p className='mb-4 font-bold'>
                If you’re a carrier, OEM, industry body or simply interested to know more and connect with us,
                send us an email: <a href='mailto:hello@worldphoneamnesty.com' target='_blank' className='text-wparoyalblue'>hello@worldphoneamnesty.com</a> 
              </p>
            </div>
          </div>
        </section>
        <FooterComponent />
      </div>
    );
}

export default AboutKingfisher;
