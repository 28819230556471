import React, {useEffect, useState, useContext} from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useScrollProgress, clampProgress, useScrollToAfterAssess } from '../../context/ScrollContext';
// import PhoneSelectModal from '../PhoneSelectModal';

import { SPRING_CONFIG } from '../../hooks/springConfig';

import loaderSpin from '../../assets/images/loader.svg';
import caretRight from '../../assets/images/icons/caretBlackRight.svg';
import caretLeft from '../../assets/images/icons/caretBlackLeft.svg';

import Button from '../Button';

import { useRegion } from '../../providers/RegionProvider'


const _SPRING_CONFIG = {
    ...SPRING_CONFIG
};


const AssessMarketComponent = ({ startOffset = 0, endOffset = 1 }) => {
  const scrollProgress = useScrollProgress(startOffset, endOffset);

  const remappedStep1Progress = clampProgress(scrollProgress, 0, 0.1);
  // const remappedStep2Progress = clampProgress(scrollProgress, 0.3, 0.4);
  // const remappedStep3Progress = clampProgress(scrollProgress, 0.4, 0.5);
  // const remappedStep4Progress = clampProgress(scrollProgress, 0.5, 0.6);
  //   const remappedStep5Progress = clampProgress(scrollProgress, 0.55, 0.65);
  //   const remappedStep6Progress = clampProgress(scrollProgress, 0.7, 0.8);


  const [{ formY }, setFormY] = useSpring(() => ({ formY: 0, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
  useEffect(() => {
    setFormY({ formY: (1 - remappedStep1Progress) * 0 });
  }, [remappedStep1Progress, setFormY]);


    const goToAfterAssessMarket = useScrollToAfterAssess();  
    const [showThing, setShowThing] = useState(false);

    const { selectedRegion, handlePrevClick, handleNextClick } = useRegion();
    
    const handleClick = () => {
      setShowThing(true);
      
      setTimeout(() => {
        goToAfterAssessMarket();
    }, 2000);

      setTimeout(() => {
          setShowThing(false);
      }, 3000);
  };

  return (
    <section id="assessPhone" className='relative bg-wparoyalblue h-[200vh] w-full'>
      <div className='sticky top-0 h-screen w-full flex items-center justify-center pt-[60px] overflow-hidden'>
        <animated.div className='z-50 w-full h-full px-6 py-2 flex flex-col items-center justify-center' style={{ transform: formY.interpolate(value => `translateY(${value}vh)`) }}>
          <div className='md:scale-125 lg:scale-150 my-7'>
            <div className='font-schabo text-4xl/[3rem] tracking-wide text-white text-center mb-1'>
                OK.
            </div>
            <div className='font-schabo text-3xl/[2rem] tracking-wide text-white uppercase text-center'>
                Let's explore the potential<br />
                impact in your market.<br />
            </div>
          </div>
          <div className='w-full flex-grow flex flex-col justify-around align-middle'>
              {/* //TODO: market select form */}
              <div className='flex flex-col h-[80%] max-h-[600px] w-full justify-center align-middle p-4 relative'>
                  <img src={selectedRegion.imgUrl} alt="Region" className="absolute top-0 left-0 w-full h-full z-0" />

                  <div className='font-schabo text-xl/[3rem] tracking-wide text-white uppercase text-center z-10'>
                      select your region
                  </div>
                  <div className='font-schabo text-6xl/[4rem] tracking-wide text-white uppercase text-center z-10'>
                    {selectedRegion.name}
                  </div>
                  <div className='flex flex-row w-full justify-center align-middle p-4 z-10 gap-3'>
                    <Button zIndex={'z-80'} textColor={'text-black'} bgColor={'bg-white'} icon={<img src={caretLeft} alt="Previous" />} isIconOnly={true} isSmall={true} onClick={handlePrevClick} />
                    <Button zIndex={'z-80'} textColor={'text-black'} bgColor={'bg-white'} icon={<img src={caretRight} alt="Next" />} isIconOnly={true} isSmall={true} onClick={handleNextClick} />
                  </div>
              </div>
              <div className='align-middle justify-center items-center flex pointer-events-auto w-full pb-11'>
                <Button 
                    textColor={'text-black'} 
                    bgColor={'bg-wpayellow'} 
                    extraClasses={'w-full text-xl max-w-[450px]'}
                    label='ASSESS MARKET' 
                    onClick={() => handleClick()}
                />      
              </div>
          </div>
        </animated.div>

        {showThing && (<animated.div className="absolute z-100 h-screen w-screen flex flex-col items-center align-middle justify-center bg-wpayellow">
          <img src={loaderSpin} alt="loading" className='animate-spin-slow max-w-[95px]'/>
          <div className='font-schabo text-5xl/[3rem] tracking-wide text-black text-center uppercase py-10'>
            Calculating<br />Impact
          </div>
        </animated.div>) }
      </div>

    </section>
  );
};

export default AssessMarketComponent;
