import React, {useState, useEffect, useRef} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Button from '../Button';

// import { animated, useSpring } from '@react-spring/web';
// import useWindowWidth from '../../hooks/useWindowWidth';
import { useScrollProgress } from '../../context/ScrollContext';
// import kingfisherName from '../../assets/images/kingfisherName_white.svg';
// import wpaLogo from '../../assets/images/wpaLogo_white.svg';
// import wpaText from '../../assets/images/wpaText.svg';

import caretUp from '../../assets/images/icons/caretUp.svg';
import caretDown from '../../assets/images/icons/arrow_down.svg';

import fullLogo from '../../assets/images/fullLogo.svg';

import { useScrollToActNow, useScrollToAssess, useScrollToIssue, useScrollToPurpose, useScrollToTop} from '../../context/ScrollContext';

import useScrollbarWidth from '../../hooks/scrollBarWidth.js';


const FooterComponent = ({ startOffset = 0, endOffset = 1 }) => {
  const scrollbarWidth = useScrollbarWidth();

  const scrollProgress = useScrollProgress(startOffset, endOffset);
  // console.log(scrollProgress);
  // const [scrollTarget, setScrollTarget] = useState(null);
  const history = useHistory();
  const location = useLocation();

  const sectionRef = useRef(null);
  // const [scrollMargin, setScrollMargin] = useState(0);

  // useEffect(() => {
  //   if (scrollTarget) {
  //     // Delay the scrolling by a small amount to ensure the DOM has updated
  //     setTimeout(() => {
  //       const element = document.getElementById(scrollTarget);
  //       if (element) {
  //         element.scrollIntoView({ behavior: 'smooth' });
  //       }
  //       // Reset the scroll target after scrolling
  //       setScrollTarget(null);
  //     }, 100);  // 100ms delay, you can adjust this value if needed
  //   }
  // }, [location, scrollTarget]);


  // useEffect(() => {
  //   // Check if the ref exists
  //   if (sectionRef.current) {
  //     // Access the offsetWidth property to get the width of the <section>
  //     const sectionWidth = sectionRef.current.offsetWidth;
  //     // console.log(`Section width: ${sectionWidth}px`);
  //     // console.log(window.innerWidth);
  //     setScrollMargin(window.innerWidth - sectionWidth);
  //   }
  // }, []);


  const goToAbout = () => {
    history.push('/about-kingfisher');
  };

  const goHome = () => {
    history.push('/');
  };


  const scrollToActNow = useScrollToActNow();
  const handleActNow = () => {
    handleLinkClick("internal", scrollToActNow);
  } 

  const scrollToPurpose = useScrollToPurpose();
  const handlePurpose = () => {
    handleLinkClick("internal", scrollToPurpose);
  } 

  const scrollToIssue = useScrollToIssue();
  const handleIssue = () => {
    handleLinkClick("internal", scrollToIssue);
  } 

  const scrollToAssess = useScrollToAssess();
  const handleAssess = () => {
    handleLinkClick("internal", scrollToAssess);
  } 

  const backToTop = useScrollToTop();
  const handleBackToTopClick = () => {
    backToTop();
  } 


  const handleLinkClick = (linkType, target) => {
    if (linkType === 'internal') {

      if (location.pathname !== "/") {
        history.push("/");
        setTimeout(() => {
          target();
        }, 500);
      } else {
        target();
      }
    } else {
      if (linkType === 'about') {
        goToAbout();
      } else {
        window.open(target, '_blank');
      }
    }
  };

  // function getScrollbarWidth() {
  //     // Create a temporary div
  //     const outer = document.createElement('div');
  //     outer.style.visibility = 'hidden';
  //     outer.style.overflow = 'scroll'; 
  //     document.body.appendChild(outer);

  //     // Get the width with scrollbar
  //     const widthWithScroll = outer.getBoundingClientRect().width;

  //     // Remove the temporary div
  //     document.body.removeChild(outer);

  //     // Calculate the scrollbar width
  //     return widthWithScroll - outer.clientWidth;
  // }

  // const scrollbarWidth = getScrollbarWidth();
  // const container = document.querySelector('#act_now'); // Replace with your container's class or ID

  // if (scrollbarWidth > 0) {
  //     container.style.width = `calc(100% - ${scrollbarWidth}px)`;
  // }

  return (
    <section className='relative w-full' ref={sectionRef}>
      <div id="act_now" className='act-now w-full flex flex-col justify-between relative pt-28'>
        <div className="container mx-auto">
          <div className="max-w-7xl mx-auto w-full px-5">
            {/* Three Columns: Logo, Links, Act Now */}
            <div className="grid grid-cols-1 lg:grid-cols-5 text-white mb-10 lg:mb-0 lg:pb-24">
              {/* World Phone Amnesty Section */}
              <div className='mb-4 flex flex-col lg:col-span-2 items-center'>
                {/* <div> */}
                  {/* <div className='mb-5 lg:mb-8 lg:ml-2'> */}
                    <img src={fullLogo} alt="World Phone Amnesty Logo" className="w-[60%] max-w-[270px]" onClick={() => handleLinkClick("external", "https://kingfisher-mx.com/")}/>
                  {/* </div> */}
                  {/* <div className="mb-6">
                      <img src={wpaText} alt="World Phone Amnesty Name" className="h-28 w-auto" />
                  </div>
                </div>
                <div className='text-white font-montserrat'>
                    Powered by
                    <img src={kingfisherName} alt="World Phone Amnesty Name" className="h-5 w-auto mt-2" />
                </div> */}
              </div>

              <div className='grid md:grid-cols-2 lg:col-span-3 md:mt-8 md:mb-4 lg:mt-28'>
                {/* Links Section */}
                <div className="mt-10">
                  <h3 className="mb-4 text-2xl lg:text-2xl  font-schabo tracking-wider text-wpayellow lg:mb-6">LINKS</h3>
                  <a href="#0" onClick={() => handleLinkClick("about", "")}><p className="font-montserrat font-bold mb-2 lg:mb-3">ABOUT KINGFISHER</p></a>
                  <a href="#0" onClick={() => handlePurpose()}><p className="font-montserrat font-bold mb-2 lg:mb-3">PURPOSE</p></a>
                  <a href="#0" onClick={() => handleIssue()}><p className="font-montserrat font-bold mb-2 lg:mb-3">ISSUE</p></a>
                  <a href="#0" onClick={() => handleAssess()}><p className="font-montserrat font-bold mb-2 lg:mb-3">ASSESS MARKET</p></a>
                  <a href="#0" onClick={() => handleActNow()}><p className="font-montserrat font-bold mb-2 lg:mb-3">ACT NOW</p></a>
                  <a href="#0" onClick={() => handleLinkClick("", "https://consumer.worldphoneamnesty.com/")}><p className="font-montserrat font-bold mb-2 lg:mb-3">WPA FOR CONSUMERS</p></a>
                </div>

                {/* Act Now Section */}
                <div className="mt-8">
                  <h3 className="mb-4 text-2xl lg:text-2xl  font-schabo tracking-wider text-wpayellow lg:mb-6">ACT NOW</h3>
                  <a href="#0" onClick={() => handleActNow()}><p className="font-montserrat font-bold mb-2 lg:mb-3">START YOUR OWN PHONE AMNESTY</p></a>
                  <a href="#0" onClick={() => handleActNow()}><p className="font-montserrat font-bold mb-2 lg:mb-3">VISIT THE KINGFISHER WEBSITE</p></a>
                  <a href="#0" onClick={() => handleActNow()}><p className="font-montserrat font-bold mb-2 lg:mb-3">CONNECT WITH KINGFISHER</p></a>
                </div>
              </div>

            </div>
            {/* Footer Links */}
            <div className='flex flex-row'>
              <div className='w-full'>
                <span className='flex w-full h-px bg-white opacity-30 mb-10'></span>
                <div className="flex flex-col md:flex-row justify-between mb-4">
                  <div className='flex flex-col gap-4 md:gap-6 md:flex-row md:order-2'>
                    <a href="#0" onClick={() => handleLinkClick("", "https://kingfisher-mx.com/privacy-policy/")}><p className='text-wpagrey-200 text-sm'>Privacy policy</p></a>
                    <a href="#0" onClick={() => handleLinkClick("", "https://kingfisher-mx.com/terms-of-service/")}><p className='text-wpagrey-200 text-sm'>Terms of Service</p></a>
                  </div>
                  <p className='text-wpagrey-200 mt-8 text-sm md:mt-0 md:order-1'>&copy;2023 - World Phone Amnesty</p>
                </div>
              </div>
            </div>
            <div className='sticky bottom-24 flex flex-row justify-end'>
              <div className='flex items-center justify-center align-middle p-2'  style={{opacity: (location.pathname === "/") ? 1 : 0}}>
                <Button zIndex={'z-80'} bgColor={'bg-wpayellow'} icon={<img src={caretUp} alt="Back to Top" />} isIconOnly={true} onClick={handleBackToTopClick} />
              </div>
            </div>
          </div>

        </div>

        <div className='fixed bottom-5 left-1/2 transform -translate-x-1/2 flex flex-row justify-center'
            style={{
              opacity: (scrollProgress > 0) ? 0 : 1,
              paddingLeft: `${scrollbarWidth}px` 
            }}>
            <div style={{opacity: (location.pathname === "/") ? 1 : 0}}>
              <img src={caretDown} alt="Caret Down Explore" className="h-6 w-auto" />
            </div>
        </div>

      </div>
    </section>
  );
};

export default FooterComponent;

