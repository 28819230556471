import React, {useEffect, useState} from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useScrollProgress, clampProgress } from '../../context/ScrollContext';
import moon from '../../../src/assets/images/moon.svg';
import phoneBlueStacked from '../../assets/images/phone_blue_stacker.svg';

import { SPRING_CONFIG } from '../../hooks/springConfig';

const _SPRING_CONFIG = {
    ...SPRING_CONFIG
};

const StackAndMoonComponent = ({ startOffset = 0, endOffset = 1 }) => {
  const scrollProgress = useScrollProgress(startOffset, endOffset);

  const remappedStep1Progress = clampProgress(scrollProgress, 0.08, 0.3);
  const remappedStep2Progress = clampProgress(scrollProgress, 0, 0.1);
  const remappedStep3Progress = clampProgress(scrollProgress, 0.4, 0.65);
//   const remappedStep4Progress = clampProgress(scrollProgress, 0.50, 0.75);
  const remappedStep5Progress = clampProgress(scrollProgress, 0.7, 0.9);

  const [moonText, setMoonText] = useState(["MOON"]);

  let moonScaleFactor;

  if (remappedStep5Progress > 0) {
      moonScaleFactor = 1 - remappedStep5Progress; // This will make the moon shrink back to 0
  } else if (remappedStep3Progress > 0) {
      moonScaleFactor = remappedStep3Progress;
  } else {
      moonScaleFactor = 0;
  }
  
  useEffect(() => {
      if (remappedStep5Progress > 0) {
          setMoonText(["BACK"]);
      } else if (moonScaleFactor >= 1) {
          setMoonText(["BACK"]);
      } else if (moonScaleFactor >= 0.6) {
          setMoonText(["AND"]);
      } else {
          setMoonText(["MOON"]);
      }
  }, [moonScaleFactor, remappedStep5Progress]);
  

    const [{ mainTextY }, setMainTextY] = useSpring(() => ({ mainTextY: 100, config: {..._SPRING_CONFIG} }));  // Starts at the bottom, 100vh
    useEffect(() => {
        setMainTextY({ mainTextY: (1 - remappedStep2Progress) * 100 });
    }, [remappedStep2Progress, setMainTextY]);


    const maxDimension = window.innerWidth > window.innerHeight ? 'vw' : 'vh';
    const dimensionValue = maxDimension === 'vw' ? window.innerWidth : window.innerHeight;

    const growBlackCircleSpring = useSpring({
        width: `${remappedStep3Progress * dimensionValue}${maxDimension}`,
        height: `${remappedStep3Progress * dimensionValue}${maxDimension}`, config: {..._SPRING_CONFIG} 
    });

    // const growMoonSpring = useSpring({
    //     width: `${remappedStep4Progress * dimensionValue * 2}${maxDimension}`,
    //     height: `${remappedStep4Progress * dimensionValue * 2}${maxDimension}`
    // });

    const growMoonSpring = useSpring({
        width: `${moonScaleFactor * window.innerHeight * 1.3}px`,
        height: `${moonScaleFactor * window.innerHeight * 1.3}px`, config: {..._SPRING_CONFIG} 
    });

    // Create a new spring for the font size using the combined scale factor
    const fontSizeSpring = useSpring({
        fontSize: moonScaleFactor, config: {..._SPRING_CONFIG} 
    });

    // Interpolate the fontSizeSpring value to generate a font size value
    const fontSize = fontSizeSpring.fontSize.interpolate([0, 1], ['0rem', '8rem']); // Adjust the range if needed


    const useCreatePhoneDropSpring = () => {
        const [spring, set] = useSpring(() => ({
          bluePhoneDropY: 50, config: {..._SPRING_CONFIG} 
        }));
        return [spring, set];
    };
   
    const remap = (value, start, end) => {
        if (value < start) return 0;
        if (value > end) return 1;
        return (value - start) / (end - start);
    };
    

      const [phone1Spring, setPhone1Drop] = useCreatePhoneDropSpring();
      const [phone2Spring, setPhone2Drop] = useCreatePhoneDropSpring();
      const [phone3Spring, setPhone3Drop] = useCreatePhoneDropSpring();
      const [phone4Spring, setPhone4Drop] = useCreatePhoneDropSpring();
      const [phone5Spring, setPhone5Drop] = useCreatePhoneDropSpring();
      const [phone6Spring, setPhone6Drop] = useCreatePhoneDropSpring();

      useEffect(() => {
        setPhone1Drop({ bluePhoneDropY: remappedStep1Progress });
        setPhone2Drop({ bluePhoneDropY: remap(remappedStep1Progress, 0.05, 1) });
        setPhone3Drop({ bluePhoneDropY: remap(remappedStep1Progress, 0.25, 1) });
        setPhone4Drop({ bluePhoneDropY: remap(remappedStep1Progress, 0.45, 1) });
        setPhone5Drop({ bluePhoneDropY: remap(remappedStep1Progress, 0.65, 1) });
        setPhone6Drop({ bluePhoneDropY: remap(remappedStep1Progress, 0.75, 1) });
        
      }, [remappedStep1Progress, setPhone1Drop, setPhone2Drop, setPhone3Drop, setPhone4Drop, setPhone5Drop, setPhone6Drop]);

  return (
    <section className='relative h-[700vh] w-full'>
      <div className='sticky bg-wpaskyblue top-0 h-screen w-full pointer-events-none flex flex-col items-center justify-center text-center pt-[120px] overflow-hidden'>
        <animated.div className='z-5 absolute flex flex-col justify-center h-full w-full' style={{ transform: mainTextY.interpolate(value => `translateY(${value}vh)`) }}>
            <div className='font-schabo text-5xl/[3.25rem] tracking-wide text-black'>
                ALL OF THOSE<br />
                PHONES PLACED<br />
                END TO END WOULD<br />
                STRETCH TO THE...<br />
            </div>
            <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full'>
                <animated.div style={growBlackCircleSpring} className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black rounded-full z-10'></animated.div>
                <animated.div
                    style={growMoonSpring} 
                    className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-11'
                >
                    <img className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' src={moon} alt="Moon Description"/>
                    <animated.div style={{ fontSize: fontSize }} className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-schabo tracking-wide z-20 text-black'>
                        {moonText.map((word, index) => (
                            <div key={index}>{word}</div>
                        ))}
                    </animated.div>
                </animated.div>
            </div>
        </animated.div>
        <div className='z-4 relative md:scale-125 lg:scale-150' style={{ opacity: remappedStep1Progress <= 0.15 ? 0 : 1 }} >
            <animated.div 
                className='z-11 relative' 
                style={{
                    transform: phone6Spring.bluePhoneDropY.interpolate(value => 
                        `translateY(${(-140 + (140 * value))}vh)`
                    )
                }}
            >
                <img className='transform -scale-x-100' alt="Phone Dropping" src={phoneBlueStacked} />
            </animated.div>
            <animated.div 
                className='z-11 relative' 
                style={{
                    transform: phone5Spring.bluePhoneDropY.interpolate(value => 
                        `translateY(${(-140 + (140 * value))}vh)`
                    )
                }}
            >
                <img src={phoneBlueStacked} alt="Phone Dropping" />
            </animated.div>
            <animated.div 
                className='z-11 relative' 
                style={{
                    transform: phone4Spring.bluePhoneDropY.interpolate(value => 
                        `translateY(${(-140 + (140 * value))}vh)`
                    )
                }}
            >
                <img className='transform -scale-x-100' alt="Phone Dropping" src={phoneBlueStacked} />
            </animated.div>
            <animated.div 
                className='z-11 relative' 
                style={{
                    transform: phone3Spring.bluePhoneDropY.interpolate(value => 
                        `translateY(${(-140 + (140 * value))}vh)`
                    )
                }}
            >
                <img src={phoneBlueStacked} alt="Phone Dropping" />
            </animated.div>
            <animated.div 
                className='z-11 relative' 
                style={{
                    transform: phone2Spring.bluePhoneDropY.interpolate(value => 
                        `translateY(${(-140 + (140 * value))}vh)`
                    )
                }}
            >
                <img className='transform -scale-x-100' alt="Phone Dropping" src={phoneBlueStacked} />
            </animated.div>
            <animated.div 
                className='z-11 relative' 
                style={{
                    transform: phone1Spring.bluePhoneDropY.interpolate(value => 
                        `translateY(${(-140 + (140 * value))}vh)`
                    )
                }}
            >
                <img src={phoneBlueStacked} alt="Phone Dropping" />
            </animated.div>
        </div>
      </div>
    </section>
  );
};

export default StackAndMoonComponent;
