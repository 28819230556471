import React, {useEffect, useState} from 'react';
import { animated, useSpring } from '@react-spring/web';
// import useWindowWidth from '../../hooks/useWindowWidth';
import { useScrollProgress, clampProgress } from '../../context/ScrollContext';

import { useRegion } from '../../providers/RegionProvider'

import { SPRING_CONFIG } from '../../hooks/springConfig';

import plane from '../../assets/images/plane.svg';
import car from '../../assets/images/car.svg';

const _SPRING_CONFIG = {
    ...SPRING_CONFIG
};

const NUM_CIRCLES = 15

const DidYouKnowComponent = ({ startOffset = 0, endOffset = 1 }) => {
  const scrollProgress = useScrollProgress(startOffset, endOffset);

  const remappedStep1Progress = clampProgress(scrollProgress, 0, 0.092);
  const remappedStep15Progress = clampProgress(scrollProgress, 0.069, 0.1149);
  
  const remappedStep2Progress = clampProgress(scrollProgress, 0.1149, 0.2069);
  const remappedStep25Progress = clampProgress(scrollProgress, 0.2184, 0.2759);
  const remappedStep27Progress = clampProgress(scrollProgress, 0.2644, 0.3103);
  
  const remappedStep3Progress = clampProgress(scrollProgress, 0.2299, 0.3678);
  const remappedStep35Progress = clampProgress(scrollProgress, 0.3448, 0.4598);
  const remappedStep37Progress = clampProgress(scrollProgress, 0.3678, 0.4368);
  
  const remappedStep4Progress = clampProgress(scrollProgress, 0.4598, 0.5402);
  const remappedStep45Progress = clampProgress(scrollProgress, 0.5402, 0.6322);
  const remappedStep47Progress = clampProgress(scrollProgress, 0.5747, 0.5977);
  
  const remappedStep5Progress = clampProgress(scrollProgress, 0.6322, 0.7701);
  const remappedStep55Progress = clampProgress(scrollProgress, 0.7701, 0.95);
  
  const remappedStep6Progress = clampProgress(scrollProgress, 0.8966, 1.0);
  
// const remappedStep65Progress = clampProgress(scrollProgress, 0.89, 0.95);
// const remappedStep67Progress = clampProgress(scrollProgress, 0.9, 0.98);


// const remappedStep7Progress = clampProgress(scrollProgress, 0.6, 0.7);
// const remappedStep8Progress = clampProgress(scrollProgress, 0.7, 0.8);
// const remappedStep9Progress = clampProgress(scrollProgress, 0.8, 0.9);

// const remappedStep7Progress = clampProgress(scrollProgress, 0.9, 1);

const { selectedRegion } = useRegion();

    // const currYear = new Date().getFullYear();
    // console.log(currYear);
    // console.log(selectedData.selectedPricing.year);

    const [{ topBorder }, setTopBorder] = useSpring(() => ({ topBorder: 0, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    useEffect(() => {
        setTopBorder({ topBorder: remappedStep1Progress });
    }, [remappedStep1Progress, setTopBorder]);

    // const [{ bottomBorder }, setBottomBorder] = useSpring(() => ({ bottomBorder: 0, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    // useEffect(() => {
    //     setBottomBorder({ bottomBorder: remappedStep2Progress });
    // }, [remappedStep2Progress, setBottomBorder]);

    const [{ slide1Y }, setSlide1Y] = useSpring(() => ({ slide1Y: 0, config: {..._SPRING_CONFIG} }));  // Starts at the bottom, 100vh

    useEffect(() => {
        if (remappedStep2Progress === 0) {
            // If remappedStep2Progress is 0, use remappedStep1Progress for the calculation
            // setSlide1Y({ slide1Y: (1 - remappedStep1Progress) * 100 });
        } else {
            // If remappedStep2Progress is not 0, use it for the calculation to move further up
            setSlide1Y({ slide1Y: -100 * remappedStep2Progress });
        }
    }, [remappedStep1Progress, remappedStep2Progress, setSlide1Y]);
    

    const [{ yearsCircle }, setYearsCircle] = useSpring(() => ({ yearsCircle: 0, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    useEffect(() => {
        setYearsCircle({ yearsCircle: remappedStep15Progress });
    }, [remappedStep15Progress, setYearsCircle]);


    const [{ slide2Y }, setSlide2Y] = useSpring(() => ({ slide2Y: 100, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    useEffect(() => {
      setSlide2Y({ slide2Y: (1 - remappedStep2Progress) * 100 });
    }, [remappedStep2Progress, setSlide2Y]);


    const initialCircles = [];
    const positions = [
        { x: 0, y: 0 }, // Top left,
        { x: 0, y: window.innerHeight}, // Top right
        { x: 0, y: window.innerHeight/2 }, // Middle right
        { x: window.innerWidth, y: 0 }, // Bottom left
        { x: window.innerWidth, y: window.innerHeight } // Bottom right
    ];

    for (let i = 0; i < NUM_CIRCLES; i++) {
        let randomX = Math.random() * window.innerWidth; 
        let randomY = Math.random() * window.innerHeight;
        const randomTargetRadius = 100 + Math.random() * 1000;
        
        if(positions[i]) {
            randomX = positions[i].x;
            randomY = positions[i].y;
        }

        initialCircles.push({
            cx: randomX,
            cy: randomY,
            targetR: randomTargetRadius
        });
    }
    const [circles, setCircles] = useState(initialCircles);


    // const [{ butTheresMoreTextBgSize }, setButTheresMoreTextBgSize] = useSpring(() => ({ butTheresMoreTextBgSize: '0% 100%', config: {..._SPRING_CONFIG}  }));
    // useEffect(() => {
    // //   if (remappedStep38Progress > 0) {
    //     setButTheresMoreTextBgSize({ butTheresMoreTextBgSize: `${remappedStep38Progress * 100}% 100%` });
    // //   }
    // }, [remappedStep38Progress, setButTheresMoreTextBgSize]);



    const [{ slide3Y }, setSlide3Y] = useSpring(() => ({ slide3Y: 100, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    useEffect(() => {
        // console.log('3', remappedStep3Progress);
        // console.log('s', scrollProgress);
      if (remappedStep4Progress === 0) {
        // If remappedStep2Progress is 0, use remappedStep1Progress for the calculation
        setSlide3Y({ slide3Y: (1 - remappedStep3Progress) * 100 });
        } else {
            // If remappedStep2Progress is not 0, use it for the calculation to move further up
            setSlide3Y({ slide3Y: -100 * remappedStep4Progress });
        }

    }, [remappedStep3Progress,remappedStep4Progress, setSlide3Y]);

    // const [{ balloonsMoving }, setBalloonsMoving] = useSpring(() => ({ balloonsMoving: 0, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    // useEffect(() => {
    //     setBalloonsMoving({ balloonsMoving: (1 - remappedStep35Progress) * 250 - 150 });
    // }, [remappedStep35Progress, setBalloonsMoving]);
    

    // const [{ balloonCircle }, setBalloonCircle] = useSpring(() => ({ balloonCircle: 0, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    // useEffect(() => {
    //     setBalloonCircle({ balloonCircle: remappedStep37Progress });
    // }, [remappedStep37Progress, setBalloonCircle]);

    // const [{ postBalloonText }, setPostBalloonText] = useSpring(() => ({ postBalloonText: 0, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    // useEffect(() => {
    //     setPostBalloonText({ postBalloonText: remappedStep38Progress });
    // }, [remappedStep38Progress, setPostBalloonText]);

    
    const [{ slide4Y }, setSlide4Y] = useSpring(() => ({ slide4Y: 100, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    useEffect(() => {

      if (remappedStep5Progress === 0) {
                // If remappedStep2Progress is 0, use remappedStep1Progress for the calculation
                setSlide4Y({ slide4Y: (1 - remappedStep4Progress) * 100 });
        } else {
            // If remappedStep2Progress is not 0, use it for the calculation to move further up
            setSlide4Y({ slide4Y: -100 * remappedStep5Progress });
        }

    }, [remappedStep4Progress,remappedStep5Progress, setSlide4Y]);

    // const [{ planeMoving }, setPlaneMoving] = useSpring(() => ({ planeMoving: 0, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    // useEffect(() => {
    //     setPlaneMoving({ planeMoving: (1 - remappedStep45Progress) * 250 - 150 });
    // }, [remappedStep45Progress, setPlaneMoving]);

    const [{ planeMoving }, setPlaneMoving] = useSpring(() => ({ planeMoving: 0, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    useEffect(() => {
        setPlaneMoving({ planeMoving: remappedStep35Progress });
    }, [remappedStep35Progress, setPlaneMoving]);

    const [{ planeCircle }, setPlaneCircle] = useSpring(() => ({ planeCircle: 0, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    useEffect(() => {
        setPlaneCircle({ planeCircle: remappedStep37Progress });
    }, [remappedStep37Progress, setPlaneCircle]);


    const [{ slide5Y }, setSlide5Y] = useSpring(() => ({ slide5Y: 100, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    useEffect(() => {

      if (remappedStep6Progress === 0) {
                // If remappedStep2Progress is 0, use remappedStep1Progress for the calculation
                setSlide5Y({ slide5Y: (1 - remappedStep5Progress) * 100 });
        } else {
            // If remappedStep2Progress is not 0, use it for the calculation to move further up
            setSlide5Y({ slide5Y: remappedStep6Progress });
        }

    }, [remappedStep5Progress,remappedStep6Progress, setSlide5Y]);


    const [{ carMoving }, setCarMoving] = useSpring(() => ({ carMoving: 0, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    useEffect(() => {
        setCarMoving({ carMoving: remappedStep45Progress });
    }, [remappedStep45Progress, setCarMoving]);

    const [{ carCircle }, setCarCircle] = useSpring(() => ({ carCircle: 0, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    useEffect(() => {
        setCarCircle({ carCircle: remappedStep47Progress });
    }, [remappedStep47Progress, setCarCircle]);



    // const [{ slide6Y }, setSlide6Y] = useSpring(() => ({ slide6Y: 100, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    // useEffect(() => {

    //     setSlide6Y({ slide6Y: (1 - remappedStep6Progress) * 100 });

    // }, [remappedStep6Progress, setSlide6Y]);

    const [{ differenceCircle }, setDifferenceCircle] = useSpring(() => ({ differenceCircle: 0, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    useEffect(() => {
        setDifferenceCircle({ differenceCircle: remappedStep55Progress });
    }, [remappedStep55Progress, setDifferenceCircle]);


    const maxWindow = window.innerWidth > window.innerHeight ? window.innerWidth : window.innerHeight;

    const [{ impactTest }, setImpactTextCircle] = useSpring(() => ({ impactTest: 50, config: {..._SPRING_CONFIG}  }));  
    useEffect(() => {
        setImpactTextCircle({ impactTest: remappedStep55Progress });
    }, [remappedStep55Progress, setImpactTextCircle]);





  return (
    <section id="afterAssess" className='relative h-[700vh] w-full'>
      <div className='sticky bg-wpaskyblue top-0 h-screen w-full pointer-events-none flex flex-col items-center justify-center pt-[60px] overflow-hidden'>
        {/* <animated.div className='w-full z-40 absolute top-0' style={{ height: topBorder.interpolate(value => `${30 - (value*30)}%`) }}>
            <div 
                className="bg-wparoyalblue w-full h-full absolute top-0"
            ></div>
        </animated.div> */}
      <animated.div className='z-50 absolute w-full h-full flex flex-col items-center justify-center' style={{ transform: slide1Y.interpolate(value => `translateY(${value}vh)`)}}>
            <div className='absolute text-left flex flex-col w-full max-w-lg pl-8'>
                <div className='font-schabo text-6xl/[4rem] tracking-wide text-black pb-3 uppercase'>
                    {selectedRegion.name}<br />
                    has {selectedRegion.activeUsers}<br />
                    active mobile<br />
                    phone users.
                </div>
                <div className='font-montserrat font-bold text-2xl text-black'>
                    If each one of those<br />
                    users with a<br />
                    device&nbsp;
                    <span className="relative">
                            {/* The word "Years" */}
                            kept it
                            {/* The animated yellow circle */}
                            <animated.div 
                                className="bg-wpayellow rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[-1]"
                                style={{
                                    width: yearsCircle.to(value => `${value * 120}px`), // double the radius for diameter
                                    height: yearsCircle.to(value => `${value * 120}px`),
                                }}
                            ></animated.div>
                        </span>&nbsp;for one<br />
                        more year...
                    <br />
                </div>
          </div>
      </animated.div>
      <animated.div className='z-60 absolute h-full w-full flex flex-col items-center justify-center bg-white' style={{ transform: slide2Y.interpolate(value => `translateY(${value}vh)`) }}>
            <div className='absolute text-left flex flex-col w-full max-w-lg pl-8'>
                <div className='w-full font-montserrat font-bold text-2xl text-black z-10'>
                    The industry would<br />
                    save {selectedRegion.co2Kg}&nbsp;<br />
                    tonnes of CO<sub>2</sub> from <br />
                    manufacture, shipping<br />
                    and first year usage.
                </div>
            </div>
        </animated.div>
        
            <svg width="100%" height="100%" className="absolute top-0 left-0 z-70 mix-blend-difference ">
                {circles.map((circle, idx) => (
                    <circle key={idx} fill="#ffffff" cx={circle.cx} cy={circle.cy} r={circle.targetR * remappedStep25Progress}></circle>
                ))}
            </svg>
            <svg width="100%" height="100%" className="absolute top-0 left-0 z-75">
                {circles.map((circle, idx) => (
                    <circle key={idx} fill="#000000" cx={circle.cx} cy={circle.cy} r={circle.targetR * remappedStep27Progress}></circle>
                ))}
            </svg>
           
        <animated.div className='z-90 absolute h-full w-full flex flex-col items-center justify-center bg-black' style={{ transform: slide3Y.interpolate(value => `translateY(${value}vh)`) }}>
            <div className='absolute text-left flex flex-col w-full max-w-lg pl-8'>
                <div className='font-schabo text-6xl/[4rem] tracking-wide text-white z-10 pb-3'>
                    THAT'S<br />
                    HUGE!<br />
                </div>
                <div className=' font-montserrat font-bold text-2xl text-white z-10'>
                    That's the same<br />
                    amount of CO<sub>2</sub> as {selectedRegion.co2Air}<br />
                    commercial airline<br />
                    flights&nbsp;
                    <span className="relative">
                        from
                        {/* The animated skyblue circle */}
                        <animated.div 
                            className="bg-wpaskyblue rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[-1]"
                            style={{
                                width: planeCircle.to(value => `${value * 120}px`), // double the radius for diameter
                                height: planeCircle.to(value => `${value * 120}px`),
                            }}
                        ></animated.div>
                            <animated.div 
                                className='z-5 absolute h-[220px] w-[220px]' 
                                style={{ 
                                    transform:  planeMoving.interpolate(value => `rotate(315deg) translateX(${(value * 300 - 135)}vw) translateY(-80px)`) 
                                }}>
                                <img src={plane} alt="Plane" className="absolute md:scale-125 lg:scale-150" style={{ width: '220px', left: '0' }} />
                            </animated.div>
                    </span><br />
                    {selectedRegion.fromCapital} to<br />
                    {selectedRegion.toDestination}
                </div>
            </div>
            {/* <animated.div 
                className='z-5 absolute h-full w-full' 
                style={{ 
                    transform: planeMoving.interpolate(value => `translateY(${value * 0.52}vh) translateX(${value * -1}vw)`) 
                }}>
                <img src={plane} alt="Yellow Balloon" className="absolute md:scale-125 lg:scale-150" style={{ width: '140px', left: '0%', bottom: '20%' }} />
            </animated.div> */}
        </animated.div>
    <animated.div className='z-100  absolute h-full w-full flex flex-col items-center justify-center bg-white' style={{ transform: slide4Y.interpolate(value => `translateY(${value}vh)`) }}>
        <div className='absolute text-left flex flex-col w-full max-w-lg pl-8 pb-[120px]'>
            <div className='font-schabo text-6xl/[4rem] tracking-wide text-black z-10 pb-3' >
                    EVEN<br />
                    BETTER...<br />
                </div>
                <div className='font-montserrat font-bold text-2xl text-black z-10'>
                    If everyone in your<br />
                    region did the same thing,<br />
                    it'd be the same as taking<br />
                    almost&nbsp;
                    <span className="relative">
                        {selectedRegion.co2Cars}
                        {/* 400&nbsp; */}
                        {/* The animated skyblue circle */}
                        <animated.div 
                            className="bg-wpayellow rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[-1]"
                            style={{
                                width: carCircle.to(value => `${value * 120}px`), // double the radius for diameter
                                height: carCircle.to(value => `${value * 120}px`),
                            }}
                        ></animated.div>
                    </span>
                    <br />
                    cars off the road<br />
                    for an entire year.
                </div>
            </div>
            <animated.div 
                className='z-5 absolute h-full w-full' 
                style={{ 
                    transform:  carMoving.interpolate(value => `translateX(${(value * (window.innerWidth + 900) - 450)}px) `) 
                }}>
                <img src={car} alt="Car" className="absolute md:scale-125 lg:scale-150" style={{ width: '300px', left: '0', bottom: '15%' }} />
            </animated.div>
      </animated.div>
      <animated.div className='z-110 absolute h-full w-full flex flex-col items-center justify-center bg-white pb-[120px] -mt-1' style={{ transform: slide5Y.interpolate(value => `translateY(${value}vh)`) }}>
        <div className='z-5 font-schabo text-4xl tracking-wide text-black text-center'>
              FOR BUSINESS,<br />
              THE IMPACTS<br />
              ARE ALSO<br />
          </div>
          <div className='z-10  font-schabo text-4xl tracking-wide text-black text-center relative'>
                
                    {/* The animated skyblue circle */}
                <animated.div 
                    className="bg-wpayellow rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[-1]"
                    style={{
                        width: differenceCircle.to(value => `${value * maxWindow*1.5}px`), // double the radius for diameter
                        height: differenceCircle.to(value => `${value * maxWindow*1.5}px`),
                    }}
                >
                    <animated.div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[-1]"
                    style={{
                        fontSize: impactTest.to(value => `${value * 100}px`)
                    }}
                >SIGNIFICANT...</animated.div>
                </animated.div>
          </div>
      </animated.div>


      </div>
    </section>
  );
};

export default DidYouKnowComponent;
