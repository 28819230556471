import React, {useEffect} from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useScrollProgress, clampProgress } from '../../context/ScrollContext';

import accessForAll from '../../../src/assets/images/accessforall.svg';
import happierCustomers from '../../../src/assets/images/happiercustomers.svg';
import increasedProfitability from '../../../src/assets/images/increasedprofitability.svg';

import { SPRING_CONFIG } from '../../hooks/springConfig';

const _SPRING_CONFIG = {
    ...SPRING_CONFIG
};


const BusinessBenefitsComponent = ({ startOffset = 0, endOffset = 1 }) => {
  const scrollProgress = useScrollProgress(startOffset, endOffset);

  // const remappedStep1Progress = clampProgress(scrollProgress, 0, 1);
  const remappedStep2Progress = clampProgress(scrollProgress, 0, 0.6);
  const remappedStep3Progress = clampProgress(scrollProgress, 0.55, 0.7);
  const remappedStep4Progress = clampProgress(scrollProgress, 0.75, 0.85);
  // const remappedStep15Progress = clampProgress(scrollProgress, 0.18, 0.24);

  // const remappedStep2Progress = clampProgress(scrollProgress, 0.2, 0.3);
  // const remappedStep23Progress = clampProgress(scrollProgress, 0.35, 0.45);
  

  // const remappedStep3Progress = clampProgress(scrollProgress, 0.3, 0.4);
  // const remappedStep35Progress = clampProgress(scrollProgress, 0.4, 0.46);

  // const remappedStep4Progress = clampProgress(scrollProgress, 0.57, 0.66);
  //   const remappedStep5Progress = clampProgress(scrollProgress, 0.67, 0.72);
  //   const remappedStep55Progress = clampProgress(scrollProgress, 0.68, 0.72);

  //   const remappedStep6Progress = clampProgress(scrollProgress, 0.76, 0.85);
  //   const remappedStep7Progress = clampProgress(scrollProgress, 0.855, 0.87);

  //   const remappedBGProgress = clampProgress(scrollProgress, 0.1, 1);
  //   const remappedBG2Progress = clampProgress(scrollProgress, 0.2, 1);

    // const [{ mainTextY }, setMainTextY] = useSpring(() => ({ mainTextY: 100, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    // useEffect(() => {
    //     setMainTextY({ mainTextY: (1 - remappedStep1Progress) * 100 });
    // }, [remappedStep1Progress, setMainTextY]);

    const [{ block1Y }, setBlock1Y] = useSpring(() => ({ block1Y: 100, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    useEffect(() => {
      setBlock1Y({ block1Y: (1 - remappedStep2Progress) * 100 });
    }, [remappedStep2Progress, setBlock1Y]);

    const [{ block2Y }, setBlock2Y] = useSpring(() => ({ block2Y: 100, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    useEffect(() => {
      setBlock2Y({ block2Y: (1 - remappedStep3Progress) * 100 });
    }, [remappedStep3Progress, setBlock2Y]);

    const [{ block3Y }, setBlock3Y] = useSpring(() => ({ block3Y: 100, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    useEffect(() => {
      setBlock3Y({ block3Y: (1 - remappedStep4Progress) * 100 });
    }, [remappedStep4Progress, setBlock3Y]);

    // const [{ mainText2Y }, setMainText2Y] = useSpring(() => ({ mainText2Y: 100, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    // useEffect(() => {
    //     setMainTextY({ mainText2Y: (1 - remappedStep2Progress) * 100 });
    // }, [remappedStep2Progress, setMainText2Y]);

    // const [{ mainText3Y }, setMainText3Y] = useSpring(() => ({ mainText3Y: 100, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    // useEffect(() => {
    //     setMainTextY({ mainText3Y: (1 - remappedStep3Progress) * 100 });
    // }, [remappedStep3Progress, setMainText3Y]);


    // const [{ bgPositionY }, setBgPositionY] = useSpring(() => ({ bgPositionY: 0, config: {..._SPRING_CONFIG} }));
    // useEffect(() => {
    //     // Calculate the new background position based on remappedBGProgress
    //     const newPosition = (remappedBGProgress * 3) * -150; // This will interpolate between 0 and -150 as remappedBGProgress goes from 0 to 1
    //     setBgPositionY({ bgPositionY: newPosition });
    // }, [remappedBGProgress, setBgPositionY]);


  return (
    <section className='relative h-[200vh] w-full'>
      <div className='bg-wpayellow sticky top-0 h-full w-full pointer-events-none flex flex-col items-center justify-center overflow-hidden'>
        {/* <animated.div className='z-50 absolute max-w-md w-full flex flex-col items-left justify-center' style={{ transform: mainTextY.interpolate(value => `translateY(${value}vh)`) }}> */}
        <div className='z-50 absolute max-w-md w-full flex flex-col items-left justify-center'>
          <animated.div className='font-schabo text-4xl/[3rem] tracking-wide w-full max-w-lg text-black p-6' style={{ transform: block1Y.interpolate(value => `translateY(${value}vh)`) }}>
              <img src={accessForAll} alt="Access For All" className="p-3.5 w-20 md:scale-125 lg:scale-150 pb-6"/>
              ACCESS FOR ALL<br />
              <div className='font-montserrat font-bold text-base text-black pt-4'>
                More customers with access to<br />
                newer premium devices, with<br />
                less of a price tag.
              </div>
          </animated.div>
          <animated.div className='font-schabo text-4xl/[3rem] tracking-wide w-full max-w-lg text-black p-6' style={{ transform: block2Y.interpolate(value => `translateY(${value}vh)`) }}>
              <img src={happierCustomers} alt="Access For All" className="p-3.5 w-20 md:scale-125 lg:scale-150 pb-6"/>
              HAPPIER<br />CUSTOMERS
              <div className='font-montserrat font-bold text-base text-black pt-4'>
                Better experiences lead to<br />happier customers, increased<br />loyalty and NPS scores.
              </div>
          </animated.div>
          <animated.div className='font-schabo text-4xl/[3rem] tracking-wide w-full max-w-lg text-black p-6' style={{ transform: block3Y.interpolate(value => `translateY(${value}vh)`) }}>
              <img src={increasedProfitability} alt="Access For All" className="p-3.5 w-20 md:scale-125 lg:scale-150 pb-6"/>
              INCREASED<br />PROFITABILITY
              <div className='font-montserrat font-bold text-base text-black pt-4'>
                Through the lens of<br />sustainability.
              </div>
          </animated.div>
        {/* </animated.div> */}
        </div>
      </div>
    </section>
  );
};

export default BusinessBenefitsComponent;
