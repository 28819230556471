import { useState, useEffect } from 'react';

const useScrollbarWidth = () => {
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useEffect(() => {
    const calculateScrollbarWidth = () => {
      // Create a temporary div element
      const outer = document.createElement('div');
      outer.style.visibility = 'hidden';
      outer.style.overflow = 'scroll'; // forcing scrollbar to appear
      document.body.appendChild(outer);

      // Create a child div of the first div and get its width
      const inner = document.createElement('div');
      outer.appendChild(inner);

      // Calculate the width of the parent div minus the child div
      const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

      // Remove the divs
      outer.parentNode.removeChild(outer);

      return scrollbarWidth;
    };

    setScrollbarWidth(calculateScrollbarWidth());
  }, []);

  return scrollbarWidth;
};

export default useScrollbarWidth;
