import React, {useState, useEffect} from 'react';
import { animated, useSpring, useSprings } from '@react-spring/web';
import Button from '../Button';
import { useHistory, useLocation } from 'react-router-dom';

import { useScrollToActNow, useScrollToAssess, useScrollToIssue, useScrollToPurpose, useScrollToTop} from '../../context/ScrollContext';

// Import the SVG
import kingfisherName from '../../assets/images/kingfisherName_black.svg';
import burger from '../../assets/images/icons/burger.svg';

import { SPRING_CONFIG } from '../../hooks/springConfig';

const _SPRING_CONFIG = {
    ...SPRING_CONFIG
};

const StickyHeader = () => {

  // const [scrollTarget, setScrollTarget] = useState(null);
  const history = useHistory();
  const location = useLocation();


  // useEffect(() => {
  //   if (scrollTarget) {
  //     // Delay the scrolling by a small amount to ensure the DOM has updated
  //     setTimeout(() => {
  //       const element = document.getElementById(scrollTarget);
  //       if (element) {
  //         element.scrollIntoView({ behavior: 'smooth' });
  //       }
  //       // Reset the scroll target after scrolling
  //       setScrollTarget(null);
  //     }, 100);  // 100ms delay, you can adjust this value if needed
  //   }
  // }, [location, scrollTarget]);



  const goToAbout = () => {
    history.push('/about-kingfisher');
  };

  const goHome = () => {
    history.push('/');
    handleKF();
  };

  // const [isRouteChange, setIsRouteChange] = useState(false);

  const [showMenuClicked, setShowMenuClicked] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const [linkAnimations, setLinkAnimations] = useSprings(5, index => ({
    opacity: 0,
    transform: 'translateY(-20px)',
    delay: 700 + 100 * index, config: {..._SPRING_CONFIG}
  }));
  
const links = [
  { type: 'about', target: '/about-kingfisher', label: 'ABOUT KINGFISHER' },
  { type: 'internal', target: useScrollToPurpose(), label: 'PURPOSE' },
  { type: 'internal', target: useScrollToIssue(), label: 'ISSUE' },
  { type: 'internal', target: useScrollToAssess(), label: 'ASSESS MARKET' },
  { type: 'internal', target: useScrollToActNow(), label: 'ACT NOW' }
];

  useEffect(() => {
    if (showMenuClicked) {
      setLinkAnimations(index => ({
        opacity: 1,
        transform: 'translateY(0)',
        delay: 700 + 100 * index
      }));
    } else {
      setLinkAnimations(index => ({
        opacity: 0,
        transform: 'translateY(-20px)',
        delay: 0
      }));
    }
  }, [showMenuClicked, setLinkAnimations]);

  const handleMenuClick = () => {
    // console.log('clicked');
    setShowMenuClicked(prev => !prev);

    if (showMenuClicked) {
      setShowOverlay(false); // If the video is already playing, close the overlay immediately
  } else {
      // setTimeout(() => {
          setShowOverlay(true);
      // }, 1500); // If the video is not playing, show the overlay after a delay
  }
  };

  useEffect(() => {
      if (showOverlay) {
          document.body.style.overflow = 'hidden';
      } else {
          document.body.style.overflow = 'auto'; // or 'visible' if that was the original value
      }

      // Cleanup function
      return () => {
          document.body.style.overflow = 'auto'; // or 'visible' if that was the original value
      };
  }, [showOverlay]);

  const scrollToActNow = useScrollToActNow();

  const handleActNow = () => {
    handleLinkClick("internal", scrollToActNow);
  } 

  const scrollToTop = useScrollToTop();

  const handleKF = () => {
    handleLinkClick("internal", scrollToTop);
  } 
  
  const handleLinkClick = (linkType, target) => {
    if (linkType === 'internal') {
      setShowMenuClicked(false);
      setShowOverlay(false);

      if (location.pathname !== "/") {
      //   // This will unlisten to the history changes after it's done
      //   const unlisten = history.listen((location, action) => {
      //     if (location.pathname === "/" && action === "PUSH") {
      //       // console.log("We have returned home");
      //       // console.log(target);
      //         target();
      //         unlisten(); // remove the listener once we're done
      //     }
      // });

      history.push("/");
      // target();
        setTimeout(() => {
          console.log('added timeout');
          target();
          // window.scrollTo(1000,0);
          // window.scrollIntoView({ behavior: 'smooth' });
          // document.getElementById("issue").scrollIntoView({ behavior: 'smooth' });
        }, 300);

      } else {
        target();
      }
    } else {
      if (linkType === 'about') {
        goToAbout();
      } else {
        window.open(target, '_blank');
      }
    }
  };

  const maxDimension = window.innerWidth > window.innerHeight ? 'vw' : 'vh';
  const dimensionValue = maxDimension === 'vw' ? window.innerWidth : window.innerHeight;

  const growPlayYellowCircleSpring = useSpring({
        from: { width: 0, height: 0 },
        to: {
            width: showMenuClicked ? dimensionValue * 3 : 0,
            height: showMenuClicked ? dimensionValue * 3 : 0
        },
        delay: showMenuClicked ? 200 : 0,
        config: { duration: 600, ..._SPRING_CONFIG }
    });

    const growPlayBlackCircleSpring = useSpring({
        from: { width: 0, height: 0 },
        to: {
            width: showMenuClicked ? dimensionValue * 3 : 0,
            height: showMenuClicked ? dimensionValue * 3 : 0
        },
        delay: showMenuClicked ? 0 : 0,
        config: { duration: 600, ..._SPRING_CONFIG }
    });

    // const overlaySpring = useSpring({
    //     opacity: showOverlay ? 1 : 0,
    //     config: { duration: 500 }
    // });

    return (
      <div className='sticky top-0 left-0 w-full z-9999 bg-white h-15 py-4 px-5 shadow-md flex items-center'>
        <div className="flex justify-between items-center w-full">
            <img src={kingfisherName} alt="KingFisher Logo" className="h-4 w-auto z-90" onClick={() => goHome()}/>

            <div className="flex space-x-5 z-80">
                <Button zIndex={'z-50'} extraClasses={'px-5'} label='ACT NOW' isSmall={true} onClick={()=> handleActNow()} />
                <div className='relative z-10'>
                    <Button zIndex={'z-80'} icon={<img src={burger} alt="Burger Icon" />} isIconOnly={true} isSmall={true} onClick={handleMenuClick} />
                    <animated.div style={growPlayYellowCircleSpring} className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-wpayellow rounded-full z-12'></animated.div>
                    <animated.div style={growPlayBlackCircleSpring} className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black rounded-full z-11'></animated.div>

                  {showOverlay && (
                    <div className="fixed top-[60px] left-0 w-screen h-full flex items-center text-center justify-center z-70 border-t-2 border-slate-900">
                      <ul className="bg-transparent font-schabo text-5xl/[3.25rem] uppercase tracking-wide">
                        {linkAnimations.map((animationStyle, index) => (
                          <animated.li key={index} style={animationStyle} className="border-t last:border-b border-wpagrey-300 pt-3 px-3">
                            <a
                              href={links[index].href || "#"}
                              onClick={() => handleLinkClick(links[index].type, links[index].target || links[index].href)}
                            >
                              {links[index].label}
                            </a>
                          </animated.li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>


            </div>

        </div>
      </div>
    );
};

export default StickyHeader;
