import React, { useState, useEffect } from 'react';
// import FadeComponent from './components/testing/FadeComponent';
// import ParallaxComponent from './components/testing/ParalaxComponent';
// import SlideComponent from './components/testing/SlideComponent';
import StickyHeader from '../components/StickyHeader';

import { ScrollProvider } from '../context/ScrollContext';

import OldNewComponent from '../components/OldNewComponent';
import ReduceEnvironmentalImpactComponent from '../components/ReduceEnvironmentalImpactComponent';
import FooterComponent from '../components/FooterComponent';
import ActNowComponent from '../components/ActNowComponent';
// import BigDifferenceComponent from '../components/testing/BigDifferenceComponent';
// import EvenBetterComponent from '../components/testing/EvenBetterComponent';
// import JustImagineComponent from '../components/testing/JustImagineComponent';
// import GoodStartComponent from '../components/testing/GoodStartComponent';
import DidYouKnowComponent from '../components/DidYouKnowSmokeComponent';
import AssessMarketComponent from '../components/AssessMarketComponent';
import CircularityComponent from '../components/CircularityComponent';
import OneForOneComponent from '../components/OneForOneComponent';
import StackAndMoonComponent from '../components/StackAndMoonComponent';
import BusinessBenefitsComponent from '../components/BusinessBenefitsComponent';
import MakesSenseComponent from '../components/MakesSenseComponent';

function MainPage() {
  // Define the total height and heights array
  // const totalHeight = 4350;
  // const heights = [400, 1200, 700, 300, 200, 200, 700, 200, 200, 150, 100];

  // State to hold the calculated offsets
  // const [offsets, setOffsets] = useState([]);

  // Function to calculate offsets
  // const calculateOffsets = (heights, totalHeight) => {
  //   let startOffset = 0;
  //   let endOffset = 0;
  //   return heights.map((height, index) => {
  //     endOffset += height / totalHeight;
  //     const offset = {
  //       startOffset: startOffset.toFixed(4),
  //       endOffset: endOffset.toFixed(4)
  //     };
  //     startOffset = endOffset; // Update for the next iteration
  //     return offset;
  //   });
  // };

  // Effect to calculate offsets on mount
  // useEffect(() => {
  //   const calculatedOffsets = calculateOffsets(heights, totalHeight);
  //   setOffsets(calculatedOffsets);
  // }, []);


    return (
      <ScrollProvider>

          <div className="fixed top-0 z-50 h-15 bg-white shadow-md w-full">
            <StickyHeader />
          </div>
  
      {/* {offsets.length > 0 && (
        <> */}
          <OldNewComponent startOffset={0} endOffset={0.0899} />
          <ReduceEnvironmentalImpactComponent startOffset={0.0899} endOffset={0.3596} />
          <StackAndMoonComponent startOffset={0.3596} endOffset={0.5169} />
          <OneForOneComponent startOffset={0.5169} endOffset={0.5843} />
          <CircularityComponent startOffset={0.5843} endOffset={0.6292} />
          <AssessMarketComponent startOffset={0.6292} endOffset={0.6742} />
          <DidYouKnowComponent startOffset={0.6742} endOffset={0.8315} />
          <BusinessBenefitsComponent startOffset={0.8315} endOffset={0.8989} />
          <MakesSenseComponent startOffset={0.8989} endOffset={0.9438} />
          <ActNowComponent startOffset={0.9438} endOffset={0.9775} />
          <FooterComponent startOffset={0.9775} endOffset={1} />
        {/* </> */}
      {/* )} */}
      </ScrollProvider>
    );
  }

  export default MainPage;

  // flex-1 h-screen overflow-y-auto overflow-x-hidden scroll-smooth