import React from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useScrollProgress, clampProgress } from '../../context/ScrollContext';

// import kingfisherName from '../../assets/images/kingfisherName_white.svg';
// import wpaLogo from '../../assets/images/wpaLogo_white.svg';
// import wpaText from '../../assets/images/wpaText.svg';
// import caretDown from '../../assets/images/icons/arrow_down.svg';

import fullLogo from '../../assets/images/fullLogo.svg';


import { SPRING_CONFIG } from '../../hooks/springConfig';

const _SPRING_CONFIG = {
    ...SPRING_CONFIG
};


const OldNewComponent = ({ startOffset = 0, endOffset = 1 }) => {
  const scrollProgress = useScrollProgress(startOffset, endOffset);

    const remappedStep1Progress = clampProgress(scrollProgress, 0, 0.2);
    const remappedStep2Progress = clampProgress(scrollProgress, 0.2, 0.3);
    const remappedStep3Progress = clampProgress(scrollProgress, 0.4, 0.65);
    const remappedStep4Progress = clampProgress(scrollProgress, 0.5, 0.75);

  const growBlueCircleSpring = useSpring({
    width: remappedStep1Progress * 360,
    height: remappedStep1Progress * 360
  });

  const rotateSpring = useSpring({
    transform: `rotate(${remappedStep2Progress * 180}deg)`, config: {..._SPRING_CONFIG} 
  });

  const rotatePhoneSpring = useSpring({
    transform: `translate(-50%, -50%) rotate(${remappedStep2Progress * 180}deg)`, config: {..._SPRING_CONFIG} 
  });

  const maxDimension = window.innerWidth > window.innerHeight ? 'vw' : 'vh';
  const dimensionValue = maxDimension === 'vw' ? window.innerWidth : window.innerHeight;

  const growBlackCircleSpring = useSpring({
    width: `${remappedStep3Progress * dimensionValue}${maxDimension}`,
    height: `${remappedStep3Progress * dimensionValue}${maxDimension}`, config: {..._SPRING_CONFIG} 
  });

  const finalScreenSpring = useSpring({
    opacity: remappedStep4Progress, config: {..._SPRING_CONFIG} 
  });

  return (
    <section className='relative h-[400vh] w-full'>
      <div className='sticky top-0 h-screen w-full pointer-events-none flex flex-col items-center justify-center pt-[60px]  overflow-hidden'>
        <div className='relative h-[90%] w-full flex flex-col items-center justify-between'>
            <animated.div style={rotateSpring} className='absolute z-0 w-full h-full flex justify-center items-center origin-center'>
                <div className='h-huge w-huge flex flex-col'>
                    <div className='flex-1 w-inherit h-inherit bg-black'></div>
                    <div className='flex-1 w-inherit h-inherit bg-wpayellow'></div>
                </div>
            </animated.div>

            <animated.div style={growBlueCircleSpring} className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-wparoyalblue rounded-full z-5'></animated.div>
            <animated.div style={rotatePhoneSpring} className='absolute max-w-36 top-1/2 left-1/2 w-3/4 h-[75%] border-white border-14 rounded-3xl z-6 origin-center'></animated.div>

            {/* <animated.div style={growBlackCircleSpring} className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black rounded-full z-11'></animated.div>

            <animated.div style={finalScreenSpring} className='absolute z-12 flex flex-col items-center justify-center h-full'>
                    <img src={fullLogo} alt="World Phone Amnesty Logo" className="w-[75%] max-w-[270px]" />
            </animated.div> */}


            <div className='z-10 h-full w-full flex flex-col items-center justify-between'>
                <div className='flex items-center h-1/4 pb-[50px] justify-center font-schabo tracking-wide text-[32px]/[32px] text-white w-full'>
                    HELP YOUR CUSTOMERS GIVE THEIR
                </div>
                <animated.div style={rotateSpring} className='flex flex-col items-center h-1/2 font-schabo tracking-wide text-center justify-center'>
                    <div className='text-7xl text-wpayellow'>
                        OLD<br />PHONE
                    </div>
                    <div className='text-7xl text-white rotate-180'>
                        NEW<br />LIFE
                    </div>
                </animated.div>
                <div className='flex flex-col items-center justify-end h-1/4 font-schabo tracking-wide text-2xl pb-3'>
                    <div className='pb-5'>SCROLL TO EXPLORE</div>
                </div>
            </div>
        </div>
      </div>
    </section>
  );
};

export default OldNewComponent;
