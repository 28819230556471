
import React, {useRef, useState, useEffect} from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useScrollProgress, clampProgress } from '../../context/ScrollContext';

import play from '../../assets/images/icons/play.svg';
import phoneBlue from '../../assets/images/phone_blue.svg';
// import burger from '../../assets/images/icons/burger.svg';
import svgClose from '../../assets/images/icons/svgClose.svg'
// import video from '../../assets/video.mp4'

import Button from '../Button';
import fullLogo from '../../assets/images/fullLogo.svg';


import { SPRING_CONFIG } from '../../hooks/springConfig';

const _SPRING_CONFIG = {
    ...SPRING_CONFIG
};


const ReduceEnvironmentalImpactComponent = ({ startOffset = 0, endOffset = 1 }) => {
    const scrollProgress = useScrollProgress(startOffset, endOffset);

    const [playClicked, setPlayClicked] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);

    const videoRef = useRef(null);
    const playerxRef = useRef(null);
    const playerRef = useRef(null);

    // let player;
    // let playerTarget;

    useEffect(() => {
        // console.log(window.location.origin);
        // const onYouTubeIframeAPIReady = () => {
            
        // };
        // console.log(window.YT);

        // if(window.Vimeo && window.Vimeo.Player) {
        //     playerRef.current = new window.Vimeo.Player(videoRef.current);
        // }

        if (window.YT && window.YT.Player) {
            playerxRef.current = new window.YT.Player('player', {
                height: '80%',
                width: '100%',
                videoId: 'X4LhuiZMMCo', // your YouTube video ID
                playerVars: {
                    'playsinline': 1,
                    'origin': window.location.origin
                },
                events: {
                    'onReady': onPlayerReady,
                    'onError': onPlayerError,
                    'onStateChange': onPlayerStateChange,
                }
                
            });
            // console.log(playerxRef.current);
        }
        // console.log(playerRef.current);
    }, [window.YT.loaded]);
    
    const onPlayerReady = (event) => {
        // You can use this function to do something when the player is ready
        // console.log(event);
        playerRef.current = event.target;
        // console.log('ready');

        // setTimeout(() => {
        //     // if (playerRef.current) {
        //     //         // playerRef.current.play(); 
        //     // }
        //     console.log(playerRef.current.getPlayerState());
        //     if (playerRef.current && (playerRef.current.getPlayerState() === -1 || playerRef.current.getPlayerState() === 2 || playerRef.current.getPlayerState() === 5)) { // 1 corresponds to PLAYING state
        //         playerRef.current.playVideo();
        //     }
        // });
    };

    const onPlayerError = (event) => {
        // You can use this function to do something when the player is ready
        // console.log(event);
        // playerRef.current = event.target;
        // console.log('ready');
    };

    const onPlayerStateChange = (event) => {
        // You can use this function to do something when the player is ready
        // console.log(event);
        // playerRef.current = event.target;
        // console.log('ready');
    };

    const handlePlayClick = () => {
        // console.log('play clicked');
        setPlayClicked(true);
        // console.log(playerRef.current);
        // console.log(playerRef.current);
        // Add the class to the video
        videoRef.current.classList.add("pointer-events-auto");
    
        setTimeout(() => {
            setShowOverlay(true);
        }, 1500); // delay for the circle animations

        setTimeout(() => {
            // if (playerRef.current) {
            //         // playerRef.current.play(); 
            // }
            // console.log(playerRef.current.getPlayerState());
            if (playerRef.current && (playerRef.current.getPlayerState() === -1 || playerRef.current.getPlayerState() === 2 || playerRef.current.getPlayerState() === 5)) { // 1 corresponds to PLAYING state
                playerRef.current.playVideo();
            }

        }, 2000); // delay for the circle animations
    };

    const handleCloseClick = () => {
        // console.log(playerRef.current);
        // console.log('close clicked');
        setPlayClicked(false);
        setShowOverlay(false);
        // if (playerRef.current) {
        //     // playerRef.current.pause(); 
        // }

        if (playerRef.current && playerRef.current.getPlayerState() === 1) { // 1 corresponds to PLAYING state
            playerRef.current.pauseVideo();
        }

        videoRef.current.classList.remove("pointer-events-auto")
    };

    // useEffect(() => {
    //     const player = new Vimeo.Player(videoRef.current);

    //     const checkVisibilityAndPause = () => {
    //         if (videoRef.current) {
    //             const rect = videoRef.current.getBoundingClientRect();
    //             if (rect.width === 0 || rect.height === 0) {
    //                 player.pause();
    //             }
    //         }
    //     }

    //     // You can set this interval according to your needs
    //     const interval = setInterval(checkVisibilityAndPause, 500);

    //     return () => clearInterval(interval); // cleanup interval on unmount
    // }, []);


    const remappedStep1Progress = clampProgress(scrollProgress, 0, 0.2);
    const remappedStep15Progress = clampProgress(scrollProgress, 0.05, 0.17);
    const remappedStep2Progress = clampProgress(scrollProgress, 0.2, 0.35);
    const remappedStep25Progress = clampProgress(scrollProgress, 0.33, 0.42);
    const remappedStep26Progress = clampProgress(scrollProgress, 0.29, 0.36);
    const remappedStep27Progress = clampProgress(scrollProgress, 0.33, 0.53);
    const remappedStep3Progress = clampProgress(scrollProgress, 0.4, 0.55);
    const remappedStep35Progress = clampProgress(scrollProgress, 0.35, 0.52);
    const remappedStep4Progress = clampProgress(scrollProgress, 0.5, 0.65);
    const remappedStep5Progress = clampProgress(scrollProgress, 0.55, 0.8);
    const remappedStep6Progress = clampProgress(scrollProgress, 0.68, 0.93);

    const remappedBGProgress = clampProgress(scrollProgress, 0, 1);

    const [{ mainTextY }, setMainTextY] = useSpring(() => ({ mainTextY: 100, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    useEffect(() => {
        if (remappedStep1Progress > 0 && remappedStep1Progress <= 1) {
            setMainTextY({ mainTextY: (1 - 1 * remappedStep1Progress) * 100 - 30 });  // Scrolls in based on remappedStep1Progress
        }
        if (remappedStep2Progress > 0 && remappedStep2Progress <= 1) {
            setMainTextY({ mainTextY: -1 * remappedStep2Progress * 100 - 30});  // Scrolls out based on remappedStep2Progress
        }
        
    }, [remappedStep1Progress, remappedStep2Progress, setMainTextY]);

    const [{ mainText2Y }, setMainText2Y] = useSpring(() => ({ mainText2Y: 100, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    useEffect(() => {
        if (remappedStep2Progress > 0 && remappedStep2Progress <= 1) {
            setMainText2Y({ mainText2Y: (1 - 1 * remappedStep2Progress) * 180 });  // Scrolls in based on remappedStep2Progress
        }
        if (remappedStep3Progress > 0 && remappedStep3Progress <= 1) {
            setMainText2Y({ mainText2Y: -1 * remappedStep3Progress * 180});  // Scrolls out based on remappedStep3Progress
        }
        
    }, [remappedStep2Progress, remappedStep3Progress, setMainText2Y]);

  const [{ mainTextBgSize }, setMainTextBgSize] = useSpring(() => ({ mainTextBgSize: '0% 100%', config: {..._SPRING_CONFIG}  }));
  const [{ mainText2BgSize }, setMainText2BgSize] = useSpring(() => ({ mainText2BgSize: '0% 100%', config: {..._SPRING_CONFIG}  }));

  useEffect(() => {
    if (remappedStep15Progress > 0 && remappedStep15Progress <= 1) {
      setMainTextBgSize({ mainTextBgSize: `${remappedStep15Progress * 100 * 1.5}% 100%` });
    }
    if (remappedStep25Progress > 0 && remappedStep25Progress <= 1) {
        setMainText2BgSize({ mainText2BgSize: `${remappedStep25Progress * 100 * 1.5}% 100%` });
      }
  }, [remappedStep15Progress,remappedStep25Progress, setMainTextBgSize, setMainText2BgSize]);
  
  const [{ whyTextY }, setWhyTextY] = useSpring(() => ({ whyTextY: 100, config: {..._SPRING_CONFIG}  }));  // Starts off-screen at the bottom, 150vh
  
  useEffect(() => {
    let translateYValue = -110 * remappedStep35Progress + 100;

    const additionalTranslation = -35 * remappedStep3Progress * 2;
    translateYValue = Math.max(translateYValue + additionalTranslation, -35);
    
    setWhyTextY({ whyTextY: translateYValue });
}, [remappedStep35Progress, remappedStep3Progress, setWhyTextY]);


    const [{ eachYearTextY }, setEachYearTextY] = useSpring(() => ({ eachYearTextY: 1500, config: {..._SPRING_CONFIG}  }));  // Starts off-screen at the bottom, 150vh
    useEffect(() => {
        let translateYValue = -100 * remappedStep4Progress + 100;

        translateYValue = Math.max(translateYValue, -45);

        setEachYearTextY({ eachYearTextY: translateYValue });
    }, [remappedStep4Progress, setEachYearTextY]);

    const [{ eachYearTextBgSize }, setEachYearTextBgSize] = useSpring(() => ({ eachYearTextBgSize: '0% 100%', config: {..._SPRING_CONFIG}  }));

    useEffect(() => {
      if (remappedStep4Progress > 0 && remappedStep4Progress <= 1) {
        setEachYearTextBgSize({ eachYearTextBgSize: `${remappedStep4Progress * 100}% 100%` });
      }
    }, [remappedStep4Progress, setEachYearTextBgSize]);

  const maxDimension = window.innerWidth > window.innerHeight ? 'vw' : 'vh';
  const dimensionValue = maxDimension === 'vw' ? window.innerWidth : window.innerHeight;

//   const growBlackCircleSpring1 = useSpring({
//     width: `${remappedStep25Progress * dimensionValue}${maxDimension}`,
//     height: `${remappedStep25Progress * dimensionValue}${maxDimension}`, config: {..._SPRING_CONFIG} 
//   });

  const finalScreenSpring = useSpring({
    opacity: remappedStep26Progress, config: {..._SPRING_CONFIG} 
  });

  const growBlackCircleSpring2 = useSpring({
    width: `${remappedStep27Progress * dimensionValue}${maxDimension}`,
    height: `${remappedStep27Progress * dimensionValue}${maxDimension}`, config: {..._SPRING_CONFIG} 
  });

  const growWhiteCircleSpring2 = useSpring({
    width: `${remappedStep5Progress * dimensionValue}${maxDimension}`,
    height: `${remappedStep5Progress * dimensionValue}${maxDimension}`, config: {..._SPRING_CONFIG} 
  });

  const growPlayYellowCircleSpring = useSpring({
        from: { width: 0, height: 0 },
        to: {
            width: playClicked ? dimensionValue * 3 : 0,
            height: playClicked ? dimensionValue * 3 : 0
        },
        delay: playClicked ? 0 : 0,
        config: { duration: 1000, ..._SPRING_CONFIG }
    });

    const growPlayBlackCircleSpring = useSpring({
        from: { width: 0, height: 0 },
        to: {
            width: playClicked ? dimensionValue * 3 : 0,
            height: playClicked ? dimensionValue * 3 : 0
        },
        delay: playClicked ? 300 : 0,
        config: { duration: 1000, ..._SPRING_CONFIG }
    });

    const overlaySpring = useSpring({
        opacity: showOverlay ? 1 : 0,
        config: { duration: 500, ..._SPRING_CONFIG },
        zIndex: 100012
    });

    const phoneRef = useRef(null); // Create a ref
    const [phoneHeight, setPhoneHeight] = useState(0); // State to store the height

    const handleImageLoad = () => {
        if (phoneRef.current) {
            const height = phoneRef.current.getBoundingClientRect().height;
            // console.log(height);
            setPhoneHeight(height);
        }
    };

    const [{ bluePhoneDropY }, setBluePhoneDrop] = useSpring(() => ({ bluePhoneDropY: 0, config: {..._SPRING_CONFIG} }));  // Starts off-screen at the bottom, 150vh
    useEffect(() => {

    setBluePhoneDrop({ bluePhoneDropY: remappedStep6Progress });
        // console.log('h:',phoneHeight);
        // console.log('r:',phoneRef);
    }, [remappedStep6Progress, setBluePhoneDrop, phoneHeight]); 


    const [{ bgPositionY }, setBgPositionY] = useSpring(() => ({ bgPositionY: 0, config: {..._SPRING_CONFIG} }));
    useEffect(() => {
        // Calculate the new background position based on remappedBGProgress
        const newPosition = (remappedBGProgress * 3) * -150; // This will interpolate between 0 and -150 as remappedBGProgress goes from 0 to 1
        setBgPositionY({ bgPositionY: newPosition });
    }, [remappedBGProgress, setBgPositionY]);

    // console.log('================');
    // console.log(remappedStep5Progress);
    // console.log(window.innerHeight * remappedStep5Progress);
    // console.log(phoneHeight * 3);
    // console.log(((window.innerHeight * remappedStep5Progress) - (phoneHeight *3)));
    // console.log((phoneHeight * 3)*remappedStep5Progress);
    // console.log(((window.innerHeight * remappedStep5Progress) - (phoneHeight)) - ((phoneHeight)*remappedStep5Progress));
    // console.log('================');

return (
    <section className='relative h-[1200vh] w-full' >
      <div className='sticky top-0 h-screen w-full pointer-events-none flex flex-col items-center justify-center bg-black pt-[60px] overflow-hidden'>
        <animated.div className={`relative h-screen w-full flex flex-col items-center justify-center ${remappedStep4Progress === 1 ? "" : "bg-hold-phone bg-center bg-no-repeat bg-overlay bg-[length:auto_130%]"}`}
            style={{ 
                backgroundPositionY: bgPositionY.interpolate(y => `${y}px`) 
            }}
        >
            <animated.div hidden={remappedStep1Progress <= 0} style={overlaySpring} className="fixed inset-0 flex items-center justify-center bg-black z-50">
                {/* <video ref={videoRef} id="video"  className="w-full h-auto" controls> 
                    <source src="https://vimeo.com/873147761/862bc00d9d?share=copy" type="video/mp4" />
                    Your browser does not support the video tag.
                </video> */}
                {/* <iframe id="video" src="https://player.vimeo.com/video/873147761?h=862bc00d9d" className='pointer-events-auto' width="100%" height="auto" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe> */}
                <div ref={videoRef} className="w-full h-full relative">
                    {/* <iframe src="https://player.vimeo.com/video/873147761?h=862bc00d9d&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" className="absolute top-0 left-0 w-full h-full" title="Kingfisher World Phone Amnesty - WIP"></iframe> */}
                    <div id="player"></div>
                </div>

                <button onClick={handleCloseClick} className="absolute bottom-20 center-x text-black bg-white px-2 py-2 rounded-full pointer-events-auto">
                    <img src={svgClose} alt="Close Icon" />
                </button>
            </animated.div>

           <animated.div id="purpose" className='z-5 h-full w-full absolute flex flex-col items-center justify-center' style={{ top: mainTextY.interpolate(value => `${value}vh`) }}>
                <div className='font-schabo text-5xl/[3.25rem] tracking-wide w-full max-w-lg text-white p-6'>
                    OUR MISSION IS TO<br />
                    <animated.span     style={{ 
        WebkitBackgroundSize: mainTextBgSize,
        MozBackgroundSize: mainTextBgSize,
        OBackgroundSize: mainTextBgSize,
        backgroundSize: mainTextBgSize 
    }}  className='text-black bg-wpayellow-gradient bg-no-repeat apple:pt-3 pt-3 -ml-10 pl-10 pr-2'>REDUCE THE</animated.span><br />
                    <animated.span     style={{ 
        WebkitBackgroundSize: mainTextBgSize,
        MozBackgroundSize: mainTextBgSize,
        OBackgroundSize: mainTextBgSize,
        backgroundSize: mainTextBgSize 
    }}  className='text-black bg-wpayellow-gradient bg-no-repeat apple:pt-3 pt-3 -ml-10 pl-10 pr-2'>ENVIRONMENTAL</animated.span><br />
                    <animated.span     style={{ 
        WebkitBackgroundSize: mainTextBgSize,
        MozBackgroundSize: mainTextBgSize,
        OBackgroundSize: mainTextBgSize,
        backgroundSize: mainTextBgSize 
    }}  className='text-black bg-wpayellow-gradient bg-no-repeat apple:pt-3 pt-3 -ml-10 pl-10 pr-2'>IMPACT OF MOBILE</animated.span><br />
                    <animated.span     style={{ 
        WebkitBackgroundSize: mainTextBgSize,
        MozBackgroundSize: mainTextBgSize,
        OBackgroundSize: mainTextBgSize,
        backgroundSize: mainTextBgSize 
    }}  className='text-black bg-wpayellow-gradient bg-no-repeat apple:pt-3 pt-3 -ml-10 pl-10 pr-2'>PHONES</animated.span> BY KEEPING<br />
                    MORE IN CIRCULATION.<br />
                </div>
                </animated.div>
                <animated.div id="purpose" className='z-5 h-full w-full absolute flex flex-col items-center justify-center gap-4' style={{ top: mainText2Y.interpolate(value => `${value}vh`) }}>
                <div className='z-10 font-schabo text-5xl/[3.25rem] tracking-wide text-white w-auto mx-3 pb-5'>
                    SO WE CREATED<br />
                    {/* <animated.span     style={{ 
        WebkitBackgroundSize: mainText2BgSize,
        MozBackgroundSize: mainText2BgSize,
        OBackgroundSize: mainText2BgSize,
        backgroundSize: mainText2BgSize 
    }}  className='text-black bg-wpayellow-gradient bg-no-repeat apple:pt-3 pt-3 -ml-10 pl-10 pr-2'>WORLD PHONE</animated.span><br />
                    <animated.span     style={{ 
        WebkitBackgroundSize: mainText2BgSize,
        MozBackgroundSize: mainText2BgSize,
        OBackgroundSize: mainText2BgSize,
        backgroundSize: mainText2BgSize 
    }}  className='text-black bg-wpayellow-gradient bg-no-repeat apple:pt-3 pt-3 -ml-10 pl-10 pr-2'>AMNESTY</animated.span><br /> */}
                </div>
                <animated.div style={finalScreenSpring} className='z-12 flex flex-col items-center justify-center'>
                    <img src={fullLogo} alt="World Phone Amnesty Logo" className="w-[75%] max-w-[270px]" />
                </animated.div>
                <div>
                    <Button zIndex={'z-50'} textColor={'text-black'} bgColor={'bg-wpayellow'} extraClasses={'px-5 mt-5'} label='WATCH FILM' onClick={()=> handlePlayClick()} />

                    <animated.div style={growPlayYellowCircleSpring} className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-wpayellow rounded-full z-11'></animated.div>
                    <animated.div style={growPlayBlackCircleSpring} className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black rounded-full z-12'></animated.div>
                </div>
                <animated.div style={growBlackCircleSpring2} className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black rounded-full z-4'></animated.div>
            </animated.div>

            <animated.div hidden={remappedStep3Progress < 0} className='z-20 text-center flex flex-col items-center justify-center absolute h-screen mt-5' style={{ top: whyTextY.interpolate(value => `${value}vh`) }}>
    
                {/* <animated.div style={growBlackCircleSpring2} className='absolute min-h-43 min-w-43 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black rounded-full z-10'></animated.div> */}
                <animated.div style={growWhiteCircleSpring2} className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full z-11'></animated.div>
                            
                <div className={`pt-[0.75rem] apple:pt-[0.69rem] font-schabo text-5xl/[3.25rem] tracking-wide z-20 text-white mix-blend-difference`}>WHY?</div>
            </animated.div>

            <animated.div id="issue" hidden={remappedStep5Progress < 0} className='z-22 text-center flex flex-col items-center justify-center absolute h-screen mt-5' style={{ top: eachYearTextY.interpolate(value => `${value}vh`) }}>
                <div className=' font-schabo text-5xl/[3.25rem] tracking-wide text-black text-center'>
                    EACH YEAR,<br />
                    {/* <span className=' bg-wpayellow pt-3.5 -ml-3 pl-3 pr-2'>5.3 BILLION</span><br /> */}
                    <animated.span     style={{ 
        WebkitBackgroundSize: eachYearTextBgSize,
        MozBackgroundSize: eachYearTextBgSize,
        OBackgroundSize: eachYearTextBgSize,
        backgroundSize: eachYearTextBgSize 
    }}  className='text-black bg-wpayellow-gradient bg-no-repeat apple:pt-3.5 pt-3.5 -ml-3 pl-3 pr-2'>5.3 BILLION</animated.span><br />
                    PHONES ARE<br />
                    THROWN AWAY.<br />
                </div>
                <div className=' font-montserrat font-bold text-xl black text-center pt-4'>
                    Tossed in drawers, cupboards<br />
                    and landfill when they still<br />
                    have so much life to give.
                </div>
            </animated.div>
        </animated.div>
        <animated.div 
                className='z-21 absolute' 
                ref={phoneRef}
                style={{
                    transform: bluePhoneDropY.interpolate(value => 
                        `rotate(${360 * value - 90}deg) scale(${1 + (0.6 * value)})`
                    ),
                    top: ((-phoneHeight*2 + (window.innerHeight + phoneHeight) * remappedStep6Progress)) - (phoneHeight - (remappedStep6Progress*phoneHeight*2))+ (remappedStep6Progress * phoneHeight*2),
                    opacity: remappedStep6Progress === 0 ? 0 : 1
                }}
            >
                <img src={phoneBlue} alt="phone shape" onLoad={handleImageLoad}/>
            </animated.div>
      </div>
    </section>
  );
};

export default ReduceEnvironmentalImpactComponent;
