import React, {useEffect, useState, useRef} from 'react';
import { animated, useSpring } from '@react-spring/web';
// import useWindowWidth from '../../hooks/useWindowWidth';
import { useScrollProgress, clampProgress } from '../../context/ScrollContext';

import { SPRING_CONFIG } from '../../hooks/springConfig';
import phoneBlue from '../../assets/images/phone_blue.svg';

// import phoneBlue from '../../assets/images/phone_blue.svg';

const _SPRING_CONFIG = {
    ...SPRING_CONFIG
};

// const NUM_CIRCLES = 30;

const OneForOneComponent = ({ startOffset = 0, endOffset = 1 }) => {
    const scrollProgress = useScrollProgress(startOffset, endOffset);

    const remappedStep1Progress = clampProgress(scrollProgress, 0, 0.15);
    
    // const remappedStep2Progress = clampProgress(scrollProgress, 0.18, 0.6);
    const remappedStep3Progress = clampProgress(scrollProgress, 0, 0.7);
    // const remappedStep4Progress = clampProgress(scrollProgress, 0.5, 0.85);
    // const remappedStep5Progress = clampProgress(scrollProgress, 0.75, 0.9);
  

    // const initialCircles = [];
    // const positions = [
    //     { x: 0, y: 0 }, // Top left,
    //     { x: window.innerWidth, y: 0}, // Top right
    //     { x: window.innerWidth, y: window.innerHeight / 2 }, // Middle right
    //     { x: 0, y: window.innerHeight }, // Bottom left
    //     { x: window.innerWidth, y: window.innerHeight } // Bottom right
    // ];

    // for (let i = 0; i < NUM_CIRCLES; i++) {
    //     let randomX = Math.random() * window.innerWidth; 
    //     let randomY = Math.random() * window.innerHeight;
    //     const randomTargetRadius = 100 + Math.random() * 1000;
        
    //     if(positions[i]) {
    //         randomX = positions[i].x;
    //         randomY = positions[i].y;
    //     }

    //     initialCircles.push({
    //         cx: randomX,
    //         cy: randomY,
    //         targetR: randomTargetRadius
    //     });
    // }

    // const [circles, setCircles] = useState(initialCircles);

  const [{ mainTextY }, setMainTextY] = useSpring(() => ({ mainTextY: 100, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
  useEffect(() => {
    setMainTextY({ mainTextY: 100 - remappedStep1Progress * 100 });
  }, [remappedStep1Progress, setMainTextY]);

  // const [{ littlePhoneTextY }, setLittlePhoneTextY] = useSpring(() => ({ littlePhoneTextY: 0, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
  // useEffect(() => {
  //   setLittlePhoneTextY({ littlePhoneTextY: remappedStep2Progress });
  // }, [remappedStep2Progress, setLittlePhoneTextY]);
  
  const phoneRef = useRef(null); // Create a ref
  const [phoneWidth, setPhoneWidth] = useState(0); // State to store the height

  const handleImageLoad = () => {
      if (phoneRef.current) {
          const width = phoneRef.current.getBoundingClientRect().width;
          // console.log(height);
          setPhoneWidth(width);
      }
  };

  const [{ bluePhoneDropY }, setBluePhoneDrop] = useSpring(() => ({ bluePhoneDropY: 0, config: {..._SPRING_CONFIG} }));
  useEffect(() => {

    setBluePhoneDrop({ bluePhoneDropY: remappedStep3Progress });
        // console.log('h:',phoneHeight);
        // console.log('r:',phoneRef);
    }, [remappedStep3Progress, setBluePhoneDrop, phoneWidth]); 
  // const countingValue = Math.floor(remappedStep1Progress * 83);

  // const maxDimension = window.innerWidth > window.innerHeight ? 'vw' : 'vh';
  // const dimensionValue = maxDimension === 'vw' ? window.innerWidth : window.innerHeight;

  // const growBluePhoneSpring = useSpring({
  //   width: `${((remappedStep3Progress) * dimensionValue)}px`,
  //   height: 'auto', config: {..._SPRING_CONFIG},
  //   transform: `translate(-50%, -50%) rotate(${remappedStep3Progress * 90}deg)`,
  // });

    return (
      <section className={'bg-white relative h-[300vh] w-full'}>
        <div className='sticky top-0 h-screen w-full pointer-events-none flex flex-col items-center justify-center bg-white pt-[60px] overflow-hidden'>
        <animated.div className='z-20 absolute flex flex-col items-center justify-center text-center -m-12' style={{ transform: mainTextY.interpolate(value => `translateY(${value}vh)`)}}>
            <div className='font-schabo text-5xl/[3.25rem] tracking-wide text-black pb-2'>
                THROUGH A
            </div>
            <div className='font-schabo text-5xl/[3.25rem] tracking-wide text-black  text-center'>
                    1-FOR-1
                <br />
                DEVICE<br />
                EXCHANGE<br />
                <div className=' font-montserrat text-base font-bold text-black text-center pt-4'>
                  Where for every new phone<br />purchased, an old one is<br /> handed back to be given a<br />new life... 
                </div>
            </div>
        </animated.div>
        <animated.div 
                className='z-18 absolute' 
                ref={phoneRef}
                style={{
                    transform: bluePhoneDropY.interpolate(value => 
                        `translateX(${(value * window.innerWidth) - (window.innerWidth / 2) - 150 + (value * 250)}px) rotate(${-360 * value - 90}deg)`
                    )
                }}
            >
                <img src={phoneBlue} alt="phone shape" onLoad={handleImageLoad}/>
            </animated.div>
            <animated.div 
                className='z-18 absolute' 
                ref={phoneRef}
                style={{
                    transform: bluePhoneDropY.interpolate(value => 
                        `translateX(${(1 - value) * (window.innerWidth) - (window.innerWidth / 2) + 150 - (value * 250)}px) rotate(${360 * value - 90}deg)`
                    )
                }}
            >
                <img src={phoneBlue} alt="phone shape" onLoad={handleImageLoad}/>
            </animated.div>
            </div>
      </section>
    );
};

export default OneForOneComponent;
