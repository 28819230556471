import React, {useEffect} from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useScrollProgress, clampProgress } from '../../context/ScrollContext';

import { SPRING_CONFIG } from '../../hooks/springConfig';

const _SPRING_CONFIG = {
    ...SPRING_CONFIG
};


const MakesSenseComponent = ({ startOffset = 0, endOffset = 1 }) => {
  const scrollProgress = useScrollProgress(startOffset, endOffset);

  const remappedStep1Progress = clampProgress(scrollProgress, 0.1, 0.3);
  const remappedStep15Progress = clampProgress(scrollProgress, 0.2, 0.9);

  // const remappedBGProgress = clampProgress(scrollProgress, 0.1, 1);

  const [{ mainTextY }, setMainTextY] = useSpring(() => ({ mainTextY: 100, config: {..._SPRING_CONFIG}  }));
  useEffect(() => {
      setMainTextY({ mainTextY: (1 - remappedStep1Progress) * 100 });
  }, [remappedStep1Progress, setMainTextY]);

  const [{ bgPosition }, setBgPosition] = useSpring(() => ({ bgPosition: 0, config: {..._SPRING_CONFIG} }));
  useEffect(() => {
      setBgPosition({ bgPosition: remappedStep15Progress });
  }, [remappedStep15Progress, setBgPosition]);

  return (
    <section className='relative h-[200vh] w-full'>
      <animated.div className='sticky top-0 h-screen w-full pointer-events-none flex flex-col items-center justify-center bg-white overflow-hidden'>
        <animated.div className='z-50 absolute h-full w-full flex flex-col text-center items-center justify-center' style={{ transform: mainTextY.interpolate(value => `translateY(${value}vh)`) }}>
                <div className='flex flex-col font-schabo text-6xl/[3.25rem] tracking-wide w-full max-w-lg text-black p-6 h-full justify-around'>
                  <div className='z-11 mt-8'>
                    CIRCULARITY<br />
                    MAKES SENSE<br />
                    </div>
                    <div className='z-10 relative w-full my-2 h-[40vh] -my-16'>
                        <animated.div className={`h-[150%] w-[145vw] absolute left-1/2 top-1/2 bg-phone-swap bg-cover bg-center bg-no-repeat`}
                          style={{ 
                            transform:bgPosition.interpolate(value => `translate(-50%, -50%) rotate(${value * 60 - 30}deg) scale(${1 + value * 0.2})`)
                          }}>
                        </animated.div>
                    </div>
                    <div className='z-11 mb-8'>
                    WHICHEVER WAY<br />
                    YOU LOOK AT IT<br />
                    </div>
                </div>
            </animated.div>
      </animated.div>
    </section>
  );
};

export default MakesSenseComponent;
