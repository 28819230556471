import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';


import MainPage from './pages/main';
import AboutKingfisher from './pages/aboutKingfisher';

// import ScrollToTop from './hooks/scrollToTop';

function App() {
  useEffect(() => {
    adjustVH();
    window.addEventListener('resize', adjustVH);
    if (/Mac|iPad|iPhone|iPod/.test(navigator.platform)) {
      document.body.classList.add('apple-device');
    }
    return () => {
      window.removeEventListener('resize', adjustVH);
      document.body.classList.remove('apple-device');

    };
  }, []);
  return (
    <Router>
      {/* <ScrollToTop /> */}
      <Switch>
        <Route exact path="/" component={MainPage} />
        <Route path="/about-kingfisher" component={AboutKingfisher} />
        <Route path="*" render={() => <Redirect to="/" />} />
      </Switch>
    </Router>
  );
}

function adjustVH() {
  console.log('vh call');
  const vh = window.innerHeight * 0.009;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

export default App;
