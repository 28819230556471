import React, {useEffect} from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useScrollProgress, clampProgress, useScrollToAssess } from '../../context/ScrollContext';
import earth from '../../../src/assets/images/earth.svg';
import speechBubble from '../../../src/assets/images/speechBubble.svg';

import Button from '../Button';



import { SPRING_CONFIG } from '../../hooks/springConfig';

const _SPRING_CONFIG = {
    ...SPRING_CONFIG
};


const CircularityComponent = ({ startOffset = 0, endOffset = 1 }) => {
  const scrollProgress = useScrollProgress(startOffset, endOffset);

  const remappedStep1Progress = clampProgress(scrollProgress, 0, 0.35);
  const remappedStep15Progress = clampProgress(scrollProgress, 0.05, 0.4);

  const remappedStep2Progress = clampProgress(scrollProgress, 0.7, 0.9);
  const remappedStep25Progress = clampProgress(scrollProgress, 0.4, 0.5);
  

  // const remappedStep3Progress = clampProgress(scrollProgress, 0.25, 0.46);
  // const remappedStep35Progress = clampProgress(scrollProgress, 0.4, 0.46);

  // const remappedStep4Progress = clampProgress(scrollProgress, 0.57, 0.66);
  //   const remappedStep5Progress = clampProgress(scrollProgress, 0.67, 0.72);
  //   const remappedStep55Progress = clampProgress(scrollProgress, 0.68, 0.72);

  //   const remappedStep6Progress = clampProgress(scrollProgress, 0.76, 0.85);
  //   const remappedStep7Progress = clampProgress(scrollProgress, 0.855, 0.87);

    

    const remappedBGProgress = clampProgress(scrollProgress, 0, 1);
    // const remappedBG2Progress = clampProgress(scrollProgress, 0.2, 1);

    const [{ eachYearTextBgSize }, setEachYearTextBgSize] = useSpring(() => ({ eachYearTextBgSize: '0% 100%', config: {..._SPRING_CONFIG}  }));
    useEffect(() => {
      if (remappedStep15Progress > 0) {
        setEachYearTextBgSize({ eachYearTextBgSize: `${remappedStep15Progress * 100}% 100%` });
      }
    }, [remappedStep15Progress, setEachYearTextBgSize]);

    // const [{ eachYearTextBgSize2 }, setEachYearTextBgSize2] = useSpring(() => ({ eachYearTextBgSize2: '0% 100%', config: {..._SPRING_CONFIG}  }));
    // useEffect(() => {
    //   if (remappedStep35Progress > 0 && remappedStep6Progress <= 1) {
    //     setEachYearTextBgSize2({ eachYearTextBgSize2: `${remappedStep35Progress * 100}% 100%` });
    //   }
    // }, [remappedStep35Progress, setEachYearTextBgSize2, remappedStep6Progress]);

    





    const [{ mainTextY }, setMainTextY] = useSpring(() => ({ mainTextY: 400, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    useEffect(() => {
      if (remappedStep1Progress > 0 && remappedStep1Progress <= 1) {
        setMainTextY({ mainTextY: (1 - 1 * remappedStep1Progress) * 100 });  // Scrolls in based on remappedStep1Progress
      }
      if (remappedStep2Progress > 0 && remappedStep2Progress <= 1) {
          setMainTextY({ mainTextY: -1 * remappedStep2Progress * 100});  // Scrolls out based on remappedStep2Progress
      }
    }, [remappedStep1Progress, remappedStep2Progress, setMainTextY]);


  //   const [{ orangeHandTextY }, setOrangeHandY] = useSpring(() => ({ orangeHandTextY: 100, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
  //   useEffect(() => {
  //     let baseY = (1 - remappedStep2Progress) * 100;
  //     let offset = remappedStep6Progress * 14; // Assuming remappedStep6Progress is in range [0, 1]. If not, adjust accordingly.
  //     let newY = baseY - offset; // Subtracting because we want to move up
  //     setOrangeHandY({ orangeHandTextY: newY });
  // }, [remappedStep2Progress, remappedStep6Progress, setOrangeHandY]);



    // const [{ newOneTextY }, setNewOneTextY] = useSpring(() => ({ newOneTextY: 100, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    // useEffect(() => {
    //     setNewOneTextY({ newOneTextY: (1 - remappedStep3Progress) * 100 });
    // }, [remappedStep3Progress, setNewOneTextY]);

    // const maxDimension = window.innerWidth > window.innerHeight ? 'vw' : 'vh';
    // const dimensionValue = maxDimension === 'vw' ? window.innerWidth : window.innerHeight;
    
    // const growBlueCircleSpring = useSpring({
    //   width: `${remappedStep4Progress * dimensionValue}${maxDimension}`,
    //   height: `${remappedStep4Progress * dimensionValue}${maxDimension}`, config: {..._SPRING_CONFIG}
    // });
    
    // const growEarthSpring = useSpring({
    //   width: `${remappedStep5Progress * dimensionValue}${maxDimension}`,
    //   height: `${remappedStep5Progress * dimensionValue}${maxDimension}`, config: {..._SPRING_CONFIG}
    // });

    // const [{ opac }, setOpac] = useSpring(() => ({ opac: 0, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    // useEffect(() => {
    //   setOpac({ opac: remappedStep55Progress });
    // }, [remappedStep55Progress, setOpac]);

    // const [{ psstY }, setPsstY] = useSpring(() => ({ psstY: 100, config: {..._SPRING_CONFIG}  }));  // Starts at the bottom, 100vh
    // useEffect(() => {
    //   setPsstY({ psstY: (1 - remappedStep6Progress) * 100 +25 });
    // }, [remappedStep6Progress, setPsstY]);

    const [{ bgPositionY }, setBgPositionY] = useSpring(() => ({ bgPositionY: 0, config: {..._SPRING_CONFIG} }));
    useEffect(() => {
        // Calculate the new background position based on remappedBGProgress
        const newPosition = (remappedBGProgress * 3) * -150; // This will interpolate between 0 and -150 as remappedBGProgress goes from 0 to 1
        setBgPositionY({ bgPositionY: newPosition });
    }, [remappedBGProgress, setBgPositionY]);

    const [{ bgOpacity }, setBgOpacity] = useSpring(() => ({ bgOpacity: 0, config: {..._SPRING_CONFIG} }));
    useEffect(() => {
      setBgOpacity({ bgOpacity: remappedStep25Progress });
    }, [remappedStep25Progress, setBgOpacity]);

    // const [{ bgPosition2Y }, setBgPosition2Y] = useSpring(() => ({ bgPosition2Y: 0, config: {..._SPRING_CONFIG} }));
    // useEffect(() => {
    //     // Calculate the new background position based on remappedBGProgress
    //     const newPosition = (remappedBG2Progress * 3) * -150; // This will interpolate between 0 and -150 as remappedBGProgress goes from 0 to 1
    //     setBgPosition2Y({ bgPosition2Y: newPosition });
    // }, [remappedBG2Progress, setBgPosition2Y]);

    // const goToAssessPhone = useScrollToAssess();

    // const handleLinkClick = () => {
    //   goToAssessPhone()
    // };

  return (
    <section className='relative h-[200vh] w-full bg-wparoyalblue'>
      <animated.div className={`sticky top-0 h-screen w-full pointer-events-none flex flex-col items-center justify-center pt-[60px] overflow-hidden ${remappedStep2Progress > 0 ? '' : 'bg-sit-phone bg-[length:auto_130%] bg-cover bg-no-repeat bg-overlay'}`}
      style={{ 
        backgroundPositionY: bgPositionY.interpolate(y => `${y}px`)
      }}>
        <animated.div className='z-50 absolute h-full w-full flex flex-col items-center justify-center' style={{ 
            transform: mainTextY.interpolate(value => `translateY(${value}vh)`),
            backgroundColor: bgOpacity.interpolate(value => `rgba(62, 112, 218, ${value})`)
          }}>
                <div className='font-schabo text-5xl/[3.25rem] tracking-wide w-full max-w-lg text-white p-6'>
                    ...WE CAN DRIVE<br />
                    {/* <span className='text-black bg-wpayellow pt-4 -ml-10 pl-10 pr-2'>SIMPLE THING...</span><br /> */}
                    <animated.span style={{ 
                        WebkitBackgroundSize: eachYearTextBgSize,
                        MozBackgroundSize: eachYearTextBgSize,
                        OBackgroundSize: eachYearTextBgSize,
                        backgroundSize: eachYearTextBgSize 
                    }}  className='text-black bg-wpayellow-gradient bg-no-repeat apple:pt-3 pt-3 -ml-10 pl-10 pr-2'>CIRCULARITY</animated.span><br />
                    FOR THE MOBILE<br />
                    INDUSTRY<br />
                    &nbsp;<br />
                    <animated.div className='font-montserrat text-base pt-4 text-left font-bold' 
                      style={{ 
                        transform: bgOpacity.interpolate({
                          range: [0, 0.3, 1],
                          output: ['translateY(1000%)', 'translateY(500%)', 'translateY(0%)']
                        })
                      }}>
                      whilst benefiting the planet,<br />your customers and your business.
                    </animated.div>
                </div>

            </animated.div>
            {/* <animated.div className='absolute z-60 h-full w-full bg-orange-phone bg-[length:auto_130%] bg-center bg-no-repeat flex flex-col items-center justify-center' style={{ 
                transform: orangeHandTextY.interpolate(value => `translateY(${value}vh)`),
                backgroundPositionY: bgPosition2Y.interpolate(y => `${y}px`) 
              }}> */}
                {/* <animated.div className='font-schabo text-5xl/[3.25rem] tracking-wide w-full max-w-lg text-white p-6 pb-[120px]' style={{ transform: newOneTextY.interpolate(value => `translateY(${value}vh)`) }}>
                    HAND IN YOUR
                    OLD<br />PHONE WHEN YOU<br />

                    <animated.span     style={{ 
                        WebkitBackgroundSize: eachYearTextBgSize2,
                        MozBackgroundSize: eachYearTextBgSize2,
                        OBackgroundSize: eachYearTextBgSize2,
                        backgroundSize: eachYearTextBgSize2 
                    }}  className='text-black bg-wpayellow-gradient bg-no-repeat apple:pt-3 pt-3 -ml-1 pl-2 pr-2'>GET A NEW ONE.</animated.span><br />

                </animated.div> */}
                {/* <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full' style={{top: '50%'}}>
                    <animated.div style={growBlueCircleSpring} className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-wparoyalblue rounded-full z-10'></animated.div>
                    <animated.img 
                        style={growEarthSpring} 
                        src={earth} 
                        alt="Earth Description"
                        className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full z-11 max-w-[90%] md:max-w-[75%] lg:max-w-[65%]'
                    />
                            
                    <div style={{opacity: remappedStep55Progress}} className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-schabo text-5xl/[3.25rem] tracking-wide z-20 text-white'>
                        THE PLANET<br/>WILL THANK<br/>YOU FOR IT.
                    </div>
                </div> */}
            {/* </animated.div> */}
            {/* <animated.div className='z-80 p-4 flex flex-col items-center justify-center absolute w-full pointer-events-auto max-w-[400px]' style={{ transform: psstY.interpolate(value => `translateY(${value}vh)`), bottom: '45%' }}>
                <div className='relative mb-4 w-full'>
                    <img src={speechBubble} alt="Speech Bubble" className="h-auto w-full" />
                    <div className='text-black font-montserrat font-bold text-center absolute inset-0 flex items-center justify-center px-6'>
                        Oh, and we’ll pay you for it too 🤑
                    </div>
                </div>
                {/* <div className='w-full flex flex-row justify-center' style={{opacity: remappedStep7Progress  }}>
                  <Button 
                      textColor={'text-black'} 
                      bgColor={'bg-wpayellow'} 
                      extraClasses={'px-10 text-xl'}
                      label='FIND OUT HOW' 
                      onClick={() => handleLinkClick()}
                  />
                </div> 
            </animated.div> */}
      </animated.div>
    </section>
  );
};

export default CircularityComponent;
